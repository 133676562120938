import {resi18n_all_en, resi18n_all_enus, resi18n_all_ko} from "language/resi18n_all";
import {resi18nutil} from "language/resi18nutil";

const resi18n_community_page = {
  I18N_TITLE_MAIN: [
    "메인",
    "Main"
    , undefined],
  I18N_TITLE_COMMUNITY: [
    "커뮤니티",
    "Community"
    , undefined],
  I18N_TITLE_COMMUNITY_QNA: [
    "Q&A",
    "Q&A"
    , undefined],
  I18N_TITLE_COMMUNITY_BLOG: [
    "Knowledge Base",
    "Knowledge Base"
    , undefined],
  I18N_FILTER_PARTNER: ["파트너", "Partner", undefined],
  I18N_TITLE_COMMUNITY_BOARD: [
    "게시판",
    "Bulletin Board"
    , undefined],
  I18N_TITLE_COMMUNITY_SAMPLE: [
    "샘플 라이브러리",
    "Sample Library"
    , undefined],
  I18N_TITLE_COMMUNITY_RND: [
    "Colorful LS",
    "Colorful LS"
    , undefined],
  I18N_TITLE_COMMUNITY_PARTNER: [
    "LS Partners",
    "LS Partners"
    , undefined],
  I18N_TITLE_COMMUNITY_COMMON_HEADER_WRITE_BUTTON: [
    "글쓰기",
    "Write"
    , undefined],
  I18N_TITLE_COMMUNITY_BLOG_TEXT01: [
    "기술블로그에 나의 기술을 소개하고, 관련 지식을 쌓아 보세요.",
    "Share and build up your knowledge on the Tech Blog."
    , undefined],
  I18N_TITLE_COMMUNITY_BLOG_TEXT02: [
    "샘플을 첨부하고 XG5000, XP-Builder 소프트웨어에서 바로 다운받아 보세요.",
    "Upload sample files and download it directly from XG5000, XP-Builder software."
    , undefined],
  I18N_NO_DOCUMENT: [
    "조회된 자료가 없습니다.",
    "No result found."
    , undefined],
  I18N_NO_DOCUMENT_NOTICE: [
    "조회 필터를 설정해 주세요.",
    "Choose different filters"
    , undefined],
  I18N_PROFILE_NO_DOCUMENT: [
    "작성한 글이 없습니다.",
    "You haven't posted anything."
    , undefined],
  I18N_PROFILE_NO_DOCUMENT_NOTICE: [
    "글을 작성해 보세요.",
    "Try to write your posts"
    , undefined],
  I18N_PROFILE_NO_GOOD: [
    "좋아요를 표시한 자료가 없습니다.",
    "You have not liked any posts"
    , undefined],
  I18N_PROFILE_NO_GOOD_NOTICE: [
    "포스트에 좋아요를 클릭해 보세요.",
    "Give a like to helpful posts."
    , undefined],
  I18N_PROFILE_NO_BOOKMARK: [
    "저장된 자료가 없습니다.",
    "No bookmarked documents"
    , undefined],
  I18N_PROFILE_NO_BOOKMARK_NOTICE: [
    "북마크 아이콘을 클릭해 보세요.",
    "Click the bookmark icon"
    , undefined],
  I18N_PROFILE_NO_REPLY: [
    "작성한 댓글이 없습니다.",
    "You have not made any comments"
    , undefined],
  I18N_PROFILE_NO_REPLY_NOTICE: [
    "댓글을 작성해 보세요.",
    "leave any comments"
    , undefined],
  I18N_PROFILE_NO_QNNA: [
    "작성한 답변이 없습니다.",
    " You have not answered any questions."
    , undefined],
  I18N_PROFILE_NO_QNNA_NOTICE: [
    "Q&A에서 답변을 작성해 보세요.",
    "Try to answer to the questions."
    , undefined],
  I18N_COMMUNITY_FEEDBACK_ALERT: [
    "피드백이 전송되었습니다.",
    "Feedback has been sent."
    , undefined],
  I18N_COMMUNITY_FEEDBACK_CONFIRM: [
    "피드백을 전송하시겠습니까?",
    "Do you want to send feedback?"
    , undefined],
  I18N_CMMNTY_ADMIN_SORT_RECENT: ["작성일순", "By registered", undefined],
  I18N_CMMNTY_ADMIN_SORT_RECOMMEND: ["추천순", "Most Liked", undefined],
  I18N_CMMNTY_ADMIN_SORT_NOT_RECOMMEND: ["비추천순", "Most disliked", undefined],
  I18N_CMMNTY_ADMIN_SORT_HELP: ["도움돼요순", "Helpful", undefined],
  I18N_CMMNTY_ADMIN_SORT_NOT_HELP: ["부족해요순", "Not Helpful", undefined],
  I18N_CMMNTY_ADMIN_SORT_REPORT: ["신고순", "Report", undefined],


  I18N_CMMNTY_ASK: ["문의 하기", "Ask Question", undefined],
  I18N_CMMNTY_ASK_DESC: [
    '작성 내용이 “{{nickname}}”님께 메일로 전달되며, 메일 주소 체크 시 메일 주소를 함께 전달합니다.',
    'Send the content through email with the address if you check.'
    , undefined],
  I18N_CMMNTY_ASK_PLACEHOLDER: [
    "내용({{len}}자 이하 텍스트)",
    "Max. ({{len}} Text)"
    , undefined],
  I18N_CMMNTY_ASK_COMPLETE: ['문의 발송 완료되었습니다.', 'Send completed', undefined],
  I18N_CMMNTY_ASK_SEND_WITH_MY_EMAILID: [
    "내 이메일 정보 함께 보내기",
    "Send my email information",
    undefined],


  I18N_COMMUNITY_BLOG_SELECT_SORT: ["작성일순", "By registered", undefined],


  I18N_COMMUNITY_BLOG_SELECT_UPDATE: [
    "업데이트순",
    "By updated"
    , undefined],
  I18N_COMMUNITY_BLOG_SELECT_POPULAR: [
    "인기순",
    "Most popular"
    , undefined],

  I18N_COMMUNITY_BLOG_SELECT_POSTTYPE: [
    "포스트종류",
    "Post type"
    , undefined],
  I18N_COMMUNITY_BLOG_SELECT_APPLICATION_NOTE: [
    "Application Note",
    "Application Notes"
    , undefined],
  I18N_COMMUNITY_BLOG_SELECT_TECHNICAL_GUIDE: [
    "Technical Guide",
    "Technical Guides"
    , undefined],
  I18N_COMMUNITY_BLOG_SELECT_TECH_GENERAL: [
    "일반 포스트",
    "Post"
    , undefined],

  I18N_COMMUNITY_BLOG_SELECT_PRODUCT: [
    "제품",
    "Product"
    , undefined],
  I18N_COMMUNITY_BLOG_SELECT_PERIOD: [
    "기간",
    "Period type"
    , undefined],
  I18N_COMMUNITY_BLOG_SELECT_WEEK: [
    "1주",
    "1 Week"
    , undefined],
  I18N_COMMUNITY_BLOG_SELECT_1MONTH: [
    "1개월",
    "1 Month"
    , undefined],
  I18N_COMMUNITY_BLOG_SELECT_6MONTH: [
    "6개월",
    "6 Months"
    , undefined],
  I18N_COMMUNITY_BLOG_SELECT_1YEAR: [
    "1년",
    "1 Year"
    , undefined],

  I18N_COMMUNITY_BLOG_TAG: [
    "태그",
    "Tags"
    , undefined],

  I18N_COMMUNITY_PLACEHOLDER: [
    "키워드 검색",
    "Search Keywords"
    , undefined],

  I18N_TITLE_COMMUNITY_QNA_HEADER_MAIN: [
    "자동화 엔지니어링에 관한 궁금한 점을 물어보세요. 다양한 분야의 전문가들이 함께합니다.",
    "Ask questions about Automation Engineering. Experts from various fields will listen to your challenges."
    , undefined],

  I18N_TITLE_COMMUNITY_BOARD_HEADER: [
    "자유게시판 / 공지, 이벤트 / 구인 구직 등 다양한 정보를 공유하세요.",
    "Share Helpful General / Notice and Event / Job Information."
    , undefined],
  I18N_TITLE_COMMUNITY_RND_HEADER_MAIN: [
    "기술을 공유하고 함께 성장해가는 개발 문화, 우리가 추구하는 가치입니다.",
    "기술을 공유하고 함께 성장해가는 개발 문화, 우리가 추구하는 가치입니다."
    , undefined],
  I18N_TITLE_COMMUNITY_PARTNER_BOARD_HEADER: [
    "LS는 믿고 맡길 수 있는 든든한 파트너. <br />LS인은 함께 하면 더 큰 성과를 내는 사람들.",
    "LS is a faithful and relieble business partner. <br/>Collaboration with LS people leads to greater success."
    , undefined],


  I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_TIP: ['자유', 'General', undefined],
  I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_QNA: ['미채택', 'Unaccepted', undefined],
  I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_JOB_OFFER: ['구인중', 'Job Offer', undefined],
  I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_JOB_SEARCH: ['구직중', 'Job Search', undefined],
  I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_DOC_NOTI: ['공지', 'Notice', undefined],
  I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_DOC_EVT: ['이벤트 중', 'Event', undefined],
  I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_QNA_FINISH: ['채택', 'Accept', undefined],
  I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_JOB_OFFER_FINISH: ['구인완료', 'Job Offer', undefined],
  I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_JOB_SEARCH_FINISH: ['구직완료', 'Job Search', undefined],
  I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_DOC_NOTI_FINISH: ['공지', 'Notice', undefined],
  I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_DOC_EVT_FINISH: ['이벤트 마감', 'Closed Event', undefined],
  I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_FINISH: ['마감하기', "CLOSE", undefined],
  I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_CANCEL_FINISH: ['마감취소', 'Cancel Deadline', undefined],

  I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_JOB_OFFER_FILTER: ['구인', 'Job Offer', undefined],
  I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_JOB_SEARCH_FILTER: ['구직', 'Job Search', undefined],
  I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_NOTICE_FILTER: ['공지', 'Notice', undefined],
  I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_EVENT_FILTER: ['이벤트', 'Event', undefined],

  I18N_TITLE_COMMUNITY_QNA_QUESTION_STATE_ACCEPT: ["채택하기", "Accept", undefined],
  I18N_TITLE_COMMUNITY_QNA_QUESTION_STATE_CANCEL_ACCEPT: ["채택 취소", "Cancel", undefined],
  I18N_TITLE_COMMUNITY_QNA_QUESTION_STATE_TYPE_ACCEPTED: ["채택된 질문", "Accepted Question ", undefined],
  I18N_TITLE_COMMUNITY_QNA_QUESTION_STATE_TYPE_UNANSWERED: ["답변 없는 질문", "Question without answers", undefined],

  I18N_TITLE_COMMUNITY_BOARD_HEADER_WRITE_BUTTON: [
    "글쓰기",
    "Write"
    , undefined],
  I18N_DOC_VIEW_MORE: [
    "더보기",
    "View More"
    , undefined],
  I18N_SOLUTION_EMPLOYEE: [
    "임직원",
    "Employee"
    , undefined],
  I18N_SOLUTION_EMPLOYEE_ONLY: [
    "임직원 전용",
    "Employee Only"
    , undefined],
  I18N_COMMUNITY_FILTER_RESET: ["초기화", "Clear", undefined],
  I18N_FILTER_APPROVED: ["승인 완료", "Approved", undefined],
  I18N_FILTER_NOT_APPROVED: ["승인중", "Progressing", undefined],
  I18N_FILTER_REJECT: ["반려", "Reject", undefined],
  I18N_FILTER_DELETED: ["삭제", "Delete", undefined],
  I18N_FILTER_DEL_REQUESTED: ["삭제중", "Deleting", undefined],
  I18N_TAB_APPLICATION_NOTE: ["Application Note", "Application Note", undefined],
  I18N_TAB_TECHNICAL_GUIDE: ["Technical Guide", "Technical Guide", undefined],
  I18N_COMMUNITY_BLOG_SELECT_All: ["전체", "All", undefined],
  I18N_COMMUNITY_BLOG_TALK_IDEA_TITLE: ["이 문서가 도움이 되었나요?", "Please leave your feedbacks.", undefined],
  I18N_COMMUNITY_BLOG_HELPFUL: ["도움돼요", "Helpful", undefined],
  I18N_COMMUNITY_BLOG_NOT_HELPFUL: ["부족해요", "Not Helpful", undefined],

  I18N_COMMUNITY_BLOG_FEEDBACK_PLACEHOLDER: ["피드백을 남겨주세요.", "Tell us more...", undefined],
  I18N_COMMUNITY_BLOG_FEEDBACK_BUTTON: ["전송", "Send", undefined],

  I18N_SOLUTION_DOWNLOAD: ["다운로드", "Download", undefined],
  I18N_DOC_COL_VERSION: ["버전 ", "Version", undefined],

  I18N_COMMUNITY_USER_EXPERT: ["전문가", "Expert", undefined],

  I18N_TAB_ALL: ["전체", "All", undefined],
  I18N_COMMUNITY_FILTER_ALL: ["전체", "All", undefined],
  I18N_COMMUNITY_FILTER_RECENT: ["작성일순", "By registered", undefined],
  I18N_COMMUNITY_FILTER_UPDATED: ["업데이트순", "By updated", undefined],
  I18N_COMMUNITY_FILTER_POPULAR: ["인기순", "Most popular", undefined],
  I18N_COMMUNITY_FILTER_DOCTYPE: ["포스트 종류", "Type", undefined],
  I18N_COMMUNITY_FILTER_FINISH_STATE: ["완료 상태", "Status", undefined],
  I18N_COMMUNITY_FILTER_QUESTION_STATE: ["질문 상태", "Status", undefined],
  I18N_COMMUNITY_FILTER_FINISHED: ["완료", "Complete", undefined],
  I18N_COMMUNITY_FILTER_NOT_FINISHED: ["진행중", "Process", undefined],
  I18N_COMMUNITY_FILTER_PRODUCT: ["제품", "Product", undefined],
  I18N_COMMUNITY_FILTER_PERIOD: ["기간", "Period", undefined],
  I18N_COMMUNITY_FILTER_ONE_WEEK: ["1주", "1 Week", undefined],
  I18N_COMMUNITY_FILTER_ONE_MONTH: ["1개월", "1 Month", undefined],
  I18N_COMMUNITY_FILTER_SIX_MONTH: ["6개월", "6 Months", undefined],
  I18N_COMMUNITY_FILTER_ONE_YEAR: ["1년", "1 Year", undefined],
  I18N_COMMUNITY_FILTER_TAG: ["태그", "TAG", undefined],

  I18N_COMMUNITY_FILTER_COUNTRY: ["사이트(언어)", "Region(Language)", undefined],
  I18N_COMMUNITY_FILTER_DOC_TYPE: ["문서 종류", "Document Type", undefined],
  I18N_COMMUNITY_FILTER_ADMISSION: ["승인 상태", "Approval Status", undefined],

  I18N_COMMUNITY_FILTER_COUNTRY_KOREA: ["대한민국(한국어)", "Korea(Korean)", undefined],
  I18N_COMMUNITY_FILTER_COUNTRY_GLOBAL: ["글로벌(영어)", "Global(English)", undefined],
  I18N_COMMUNITY_FILTER_COUNTRY_USA: ["미국(영어)", "USA(English)", undefined],

  I18N_COMMUNITY_FILTER_ALERT_MANY_TAG: ["3개이하의 태그를 입력해 주세요.", "Please enter no more than 3 tags.", undefined],

  I18N_PRODUCT_CATEGORY: ["제품 카테고리", "Product Category", undefined],
  I18N_INDUSTRY_CATEGORY: ["산업 카테고리", "Industry Category", undefined],
  I18N_APPLICATION_LOAD_CATEGORY: ["적용 부하 카테고리", "Application & Load Category", undefined],

  I18N_COMMUNITY_BEST_TAG: ["베스트 태그", "Popular #hashtags", undefined],
  I18N_COMMUNITY_BEST_USER: ["베스트 회원", "Best users", undefined],
  I18N_COMMUNITY_BEST_QNA: ["베스트 질문", "Best Question", undefined],
  I18N_COMMUNITY_BEST_BLOG: ["베스트 포스트", "Best Post", undefined],
  I18N_COMMUNITY_BEST_BOARD: ["베스트 정보", "Best Information ", undefined],

  I18N_FILE_LANG_KO: ["한국어", "Korean", undefined],
  I18N_FILE_LANG_EN: ["영어", "English", undefined],
  I18N_FILE_LANG_ETC: ["기타", "Etc.", undefined],
  I18N_DOC_DOWNLOAD_COUNT: ["다운로드수", "Download Count", undefined],
  I18N_PROFILE_TAG_QNA: ["Q&A", "Q&A", undefined],
  I18N_PROFILE_TAG_RND_QNA: ["Q&A(R&D)", "Q&A(R&D)", undefined],
  I18N_PROFILE_TAG_PARTNER_QNA: ["Q&A(Partner)", "Q&A(Partner)", undefined],
  I18N_PROFILE_TAG_RND_QNA_A: ["답변(R&D)", "Answer(R&D)", undefined],
  I18N_PROFILE_TAG_PARTNER_QNA_A: ["답변(Partner)", "Answer(Partner)", undefined],
  I18N_PROFILE_QNA_ACCESS: ["채택", "", undefined],
  I18N_COMMUNITY_RATING_SAVE: ["별점 저장", "Save Rates", undefined],
  I18N_COMMUNITY_MODIFY: ["수정", "Modify", undefined],
  I18N_COMMUNITY_DELETE: ["삭제", "Delete", undefined],
  I18N_COMMUNITY_CANCEL: ["취소", "Cancel", undefined],
  I18N_COMMUNITY_SAVE: ["저장", "Save", undefined],
  I18N_ASIDE_GRID: ["개별 선택", "Multiple", undefined],
  I18N_ASIDE_SLIDER: ["범위 선택", "Range", undefined],
  I18N_COMMUNITY_COMMENT_REPORT: ["신고", "Report", undefined],
  I18N_COMMUNITY_COMMENT: ["댓글", "Comment", undefined],
  I18N_COMMUNITY_COMMENT_MODIFY_CONFIRM: ["수정을 취소하시겠습니까?", "Cancel this modification?", undefined],
  I18N_COMMUNITY_COMMENT_PLACEHOLDER: ["댓글 입력", "Add a comment", undefined],
  I18N_COMMUNITY_COMMENT_MODIFY_PLACEHOLDER: ["댓글 수정", "Modify comment", undefined],
  I18N_COMMUNITY_NOW: ["방금", "Just before", undefined],
  I18N_COMMUNITY_MINUTE: ["분전", "Minute ago", undefined],
  I18N_COMMUNITY_MINUTES: ["분전", "Minutes ago", undefined],
  I18N_COMMUNITY_HOUR: ["시간 전", "Hour ago", undefined],
  I18N_ALERT_NOT_EXPOSE: ["비공개 포스트입니다.", "This is a private post.", undefined],

  I18N_COMMUNITY_REPORT: ['신고하기', 'Report', undefined],
  I18N_COMMUNITY_REPORT_STRING1: [
    '부적절한 게시물을 신고해 주시면, 검토 후 비공개 처리됩니다. 여러 사유에 해당될 경우 대표적인 사유 1개를 선택해 주세요.',
    'Reported posts are reviewed by our staff. Please select one representative reason.', undefined],
  I18N_COMMUNITY_REPORT_STRING2: ['사유를 선택하세요', 'Report content', undefined],
  I18N_COMMUNITY_REPORT_STRING3: ['부적절한 홍보 게시물', 'Inappropriate adverts', undefined],
  I18N_COMMUNITY_REPORT_STRING4: ['음란성 또는 청소년에게 부적합한 내용', 'Sexual/Violent/Abusive content', undefined],
  I18N_COMMUNITY_REPORT_STRING5: ['명예훼손/ 사생활/ 저작권 침해 등', 'Infringes my right', undefined],
  I18N_COMMUNITY_REPORT_STRING6: ['불법 촬영물 신고', 'Unauthorized reproduction of copyright images and contents', undefined],
  I18N_COMMUNITY_REPORT_PLACEHOLDER: ['300자 이하 텍스트', 'Max. 300 text', undefined],

  I18N_COMMUNITY_SEARCH_RESET: ["검색 옵션 초기화", 'Clear Filter', undefined],
  I18N_COMMUNITY_NO_COMMENT: ["댓글 내용이 없습니다.", "No replies.", undefined],
  I18N_COMMUNITY_NO_ANSWER: ["답변 내용이 없습니다.", "There is no answer yet.", undefined],

  I18N_COMMUNITY_CONFIRM_DELETE: ["삭제 하시겠습니까?", "Do you want to delete?", undefined],
  I18N_PROFILE_PAGE_ALERT_DELETE_WRONG: ["삭제된 문서 또는 잘못된 문서입니다.", "This is a deleted or invalid document ", undefined],
  I18N_COMMUNITY_CONFIRM_DELETE_SUCCESS: ["포스트가 삭제되었습니다.", "The post has been deleted.", undefined],
  I18N_COMMUNITY_ERROR_DELETE: ["삭제에 실패하였습니다. 다시 시도해주세요.", "Fail to delete. Try again.", undefined],
  I18N_COMMUNITY_ERROR_DELETE_QNA_Q: ["답변이 있는 질문글의 경우 삭제되지 않습니다. (필요 시 페이지 하단 관리자 메일로 연락바랍니다.)", "Questions with answers will not be deleted. (If necessary, please contact the administrator account at the bottom of the page.)", undefined],
  I18N_COMMUNITY_COMPLETE_DELETE: ["삭제가 완료되었습니다.", "Delete completed.", undefined],
  I18N_COMMUNITY_QNA_LOGIN_STRING1: ['답변 달기는 로그인이 필요한 서비스 입니다.', 'Login required for reply service.', undefined],
  I18N_COMMUNITY_QNA_LOGIN_STRING2: ['회원가입 또는 로그인해 주세요.', 'Please Sign Up or Login', undefined],
  I18N_COMMUNITY_QNA_LOGIN_STRING3: ['회원가입 또는 로그인하기', 'Sign Up or Login', undefined],
  I18N_TAB_MANUAL: ["사용 설명서", "User Manuals", undefined],
  I18N_TAB_BOARD: ['게시판', 'Bulletin Board', undefined],
  I18N_TAB_CATALOG: ["카탈로그", "Catalogs", 'Catalogs'],
  I18N_TAB_SOFTWARE: ["소프트웨어", "Software", "Software"],
  I18N_TAB_CERTIFICATION: ["인증/성적서", "Certificates", 'Certificates'],
  I18N_TAB_CAD_DATA: ["2D/3D 도면", "Drawings", 'Drawings'],
  I18N_TAB_DATASHEET: ["데이터시트", "Datasheets", 'Datasheets'],
  I18N_TAB_OS: ["OS/펌웨어", "OS/Firmware", undefined],
  I18N_TAB_TIP: ["자유", "General", undefined],
  I18N_TAB_QNA_Q: ["Q&A", "Q&A", undefined],
  I18N_TAB_TECH_GENERAL: ["일반 포스트", "Post", undefined],
  I18N_TAB_SAMPLE: ["LS 샘플", "Sample", undefined],
  I18N_TAB_USER_SAMPLE: ["사용자 샘플", "User Sample", undefined],
  I18N_TAB_EDUCATION_NOTE: ["교육 자료", "Education Note", undefined],
  I18N_TAB_EDUCATION_VIDEO: ["교육 동영상", "Education Video", undefined],
  I18N_TAB_JOB_OFFER: ['구인', 'Job Offer', undefined],
  I18N_TAB_JOB_SEARCH: ['구직', 'Job Search', undefined],
  I18N_TAB_NOTICE: ['공지', 'Notice', undefined],
  I18N_TAB_EVENT: ['이벤트', 'Event', undefined],
  I18N_TAB_TROUBLE_SHOOTING: ['Troubleshooting', 'Troubleshooting', undefined],
  I18N_TAB_RND_NOTI: ["Notice(R&D)", "Notice(R&D)", undefined],
  I18N_TAB_RND_QNA_Q: ["Q&A(R&D)", "Q&A(R&D)", undefined],
  I18N_TAB_RND_TALK: ["Colorful Talk(R&D)", "Colorful Talk(R&D)", undefined],
  I18N_TAB_PARTNER_NOTI: ["공지사항(Partner)", " Notice(Partner)", undefined],
  I18N_TAB_PARTNER_QNA_Q: ["Q&A(Partner)", " Q&A(Partner)", undefined],

  I18N_COMMUNITY_HOVER_TIME: ["작성", "Write", undefined],
  I18N_COMMUNITY_HOVER_VIEW: ["조회수", "Views", undefined],
  I18N_COMMUNITY_HOVER_GOOD: ["추천", "Good", undefined],
  I18N_COMMUNITY_HOVER_BAD: ["비추천", "Bad", undefined],
  I18N_COMMUNITY_HOVER_BOOKMARK: ["북마크", "BookMark", undefined],
  I18N_COMMUNITY_HOVER_SHARE: ["공유", "Share", undefined],

  I18N_COMMUNITY_HOVER_SHARE_PROFILE: ["URL를 복사했던 이력", "History of copying URLs", undefined],
  I18N_SOLUTION_DOWNLOAD_PROFILE: ['다운로드 받았던 파일', "History of downloading files", undefined],
  I18N_DOC_WRITE_PROFILE: ['작성자 이거나 최종 수정자인 글', "The writer or the final modifier", undefined],
  I18N_ALARM_PROFILE: ['댓글/답변/상태 알림', "Comment/Answer/Status", undefined],

  I18N_SELECTOR_HMI_SOFTWARE: ['Software 형태 제품만 추천됩니다.', 'Only software-type products are recommended.', undefined],
  I18N_TAB_SW_SERVICE: ['SW 솔루션', 'Software Solution', undefined],
  I18N_PRODUCT_SELECTOR_DOC_HOVER: ['문서', "Doc", undefined],
  I18N_PRODUCT_SELECTOR_OPTION_HOVER: ['옵션', "Option", undefined],
  I18N_PRODUCT_SELECTOR_ADD_HOVER: ['담기', "Add", undefined],
  I18N_PRODUCT_SELECTOR_RELATION_DOC: ['연관 문서 보기', 'View more', undefined],
  I18N_PRODUCT_SELECTOR_EFFICIENCY: ['High Efficiency(고효율)', 'High Efficiency', undefined],
  I18N_PRODUCT_SELECTOR_PDF: ['pdf 다운로드', 'pdf Download', undefined],
  I18N_PRODUCT_SELECTOR_XLS: ['xls 다운로드', 'xls Download', undefined],
  I18N_PRODUCT_SELECTOR_OPEN: ['프로젝트 열기', "Open", undefined],
  I18N_PRODUCT_SELECTOR_SAVE: ['새 프로젝트로 저장', "Save As", undefined],
  I18N_PRODUCT_SELECTOR_SCREEN: ['전체화면', "Full screen", undefined],

  I18N_PROFILE_PAGE_EDIT_IMAGE: ['이미지 수정', 'Modify Image', undefined],
  I18N_PROFILE_PAGE_EDIT_NICKNAME: ['닉네임 수정', 'Modify Nickname', undefined],
  I18N_PROFILE_PAGE_CANCEL_BUTTON: ['취소', 'Cancel', undefined],
  I18N_PROFILE_PAGE_SAVE_BUTTON: ['저장', 'Save', undefined],
  I18N_COMMUNITY_ALERT_TAG_INVALID: ["삭제된 태그 또는 잘못된 태그입니다.", "This is a deleted or invalid tag.", undefined],
  I18N_COMMUNITY_RND_NOTICE: ["공지사항", "Notice", undefined],
  I18N_COMMUNITY_PARTNER_QNA: ["Q&A", "Q&A", undefined],
  I18N_COMMUNITY_PARTNER_DOCUMENT: ["파트너 자료", "Documents", undefined],
  I18N_COMMUNITY_PARTNER_MY: ["내 파트너만 보기", "My Partners", undefined],

  I18N_COMMUNITY_SELECT_MANUAL: ["사용 설명서", "User Manuals", undefined],
  I18N_COMMUNITY_SELECT_CATALOG: ["카탈로그", "Catalogs", 'Catalogs'],
  I18N_COMMUNITY_SELECT_CERTIFICATION: ["인증/성적서", "Certificates", undefined],
  I18N_COMMUNITY_SELECT_CAD_DATA: ["2D/3D 도면", "Drawings", "Drawings"],
  I18N_COMMUNITY_SELECT_DATASHEET: ["데이터시트", "Datasheets", "Datasheets"],
  I18N_COMMUNITY_SELECT_OS: ["OS/펌웨어", "OS/Firmware", undefined],
  I18N_COMMUNITY_SOFTWARE: ["소프트웨어", "Software", 'Software'],
  I18N_COMMUNITY_SELECT_EDUCATION_NOTE: ["교육 자료", "Training Manual", undefined],
  I18N_COMMUNITY_SELECT_EDUCATION_VIDEO: ["교육 동영상", "Training Video", undefined],
  I18N_COMMUNITY_SELECT_TROUBLESHOOTING: ["트러블슈팅", "troubleshooting", undefined],
  I18N_COMMUNITY_SELECT_SW_SERVICE: ['SW 솔루션', "SW Solution", undefined],

  I18N_COMMUNITY_SELECT_SAMPLE: ["LS 샘플", "LS Sample", undefined],
  I18N_COMMUNITY_SELECT_APPLICATION_NOTE: ["Application Note", "Application Notes", undefined],
  I18N_COMMUNITY_SELECT_TECHNICAL_GUIDE: ["Technical Guide", "Technical Guides", undefined],

  I18N_COMMUNITY_PARTNER_QNA_CONTACT: ["담당자", "Contact", undefined],
  I18N_COMMUNITY_PARTNER_QNA_STATUS_OPEN: ["접수", "Open", undefined],
  I18N_COMMUNITY_PARTNER_QNA_STATUS_PROCEED: ["진행", "Proceeding", undefined],
  I18N_COMMUNITY_PARTNER_QNA_STATUS_CLOSED: ["완료", "Closed", undefined],
  I18N_COMMUNITY_PARTNER_QNA_DDAY: ["완료 예정일", "D-day", undefined],
  I18N_COMMUNITY_PARTNER_QNA_DELAY: ["지연", "Delay", undefined],
  I18N_COMMUNITY_PARTNER_QNA_STATUS_MODIFY_POP_TIT: ["상태 수정하기", "Modify", undefined],
  I18N_COMMUNITY_PARTNER_QNA_STATUS_MODIFY_POP_TXT: ["저장시 고객에게 변경사항에 대한 메일이 발송됩니다.", "On save, the customer and the contact managers will be mailed about the changes.", undefined],
  I18N_COMMUNITY_PARTNER_QNA_STATUS_MODIFY_POP_TYPE: ["문의 유형", "Type", undefined],
  I18N_COMMUNITY_PARTNER_QNA_STATUS_MODIFY_POP_STATUS: ["상태", "Status", undefined],
  I18N_COMMUNITY_PARTNER_QNA_STATUS_MODIFY_POP_CONTACT: ["담당자", "Contact Manager", undefined],
  I18N_COMMUNITY_PARTNER_QNA_STATUS_MODIFY_POP_INQUIRY_TECH: ['기술 문의', 'Technical', undefined],
  I18N_COMMUNITY_PARTNER_QNA_STATUS_MODIFY_POP_INQUIRY_SALES: ['영업 문의', 'Sales', undefined],
  I18N_COMMUNITY_PARTNER_QNA_STATUS_MODIFY_POP_INQUIRY_SERVICE: ['서비스 문의', 'Service', undefined],
  I18N_COMMUNITY_PARTNER_QNA_STATUS_MODIFY_POP_INQUIRY_ETC: ['기타 문의', 'Others', undefined],
  I18N_COMMUNITY_PARTNER_QNA_IMG_LINK: ["Q&A 오픈 커뮤니티를<br/>이용해 보세요", "Get answers in the<br/>Q&A community.", undefined],
  I18N_COMMUNITY_PARTNER_QNA_PRODUCT: ["제품", "Product", undefined],
  I18N_COMMUNITY_PARTNER_QNA_TYPE: ["유형", "Type", undefined],
};

const [ko, en, enus] = resi18nutil.getres(resi18n_community_page);

export const resi18n_community_page_ko = {...resi18n_all_ko, ...ko};
export const resi18n_community_page_en = {...resi18n_all_en, ...en};
export const resi18n_community_page_enus = {...resi18n_all_enus, ...enus};