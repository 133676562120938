import 'react-app-polyfill/ie11';
import "react-app-polyfill/stable";
import React, {useEffect, useState} from 'react';

import Header from "./header/Header";
import Footer from "./footer/Footer";
import styled, {createGlobalStyle} from "styled-components";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {LanguageService} from "./language/LanguageService";
import {fontFamilyBold, fontFamilyMedium, fontFamilyRegular} from "./style/fonts";
import {CategoryRepository} from "./repositories/category/CategoryRepository";
import CategoryStore from "./repositories/category/CategoryStore";
import {SsqDataRepository} from "./repositories/SsqDataRepository";
import {AccountService} from "./repositories/AccountService";
import {SearchRepository2} from "repositories/SearchRepository2";
import {LoginService} from "./login/LoginService";
import LoginCallbackPage from "./LoginCallbackPage";
import {LocalStorageWrapper} from "./util/LocalStorageWrapper";
import {NotificationService} from "./repositories/NotificationService";
import {SsqImages} from "./images/SsqImages";
import {useDispatch, useSelector} from "react-redux";
import {IfSvrConf} from "models/IfSvrConf";
import {ACTION_TYPES} from "store/ActionTypes";
import {UrlLinkPage} from "./pages/urllink/UrlLinkPage";
import svcs from "services";
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {AssetLayout} from "asset/layout/AssetLayout";
import {AssetBlankLayout} from "./asset/layout/AssetBlankLayout";
import {swrFetcher} from "util/swrutil";
import {SWRConfig} from 'swr';
import {cookieutil} from "util/cookieutil";
import TagManager from "react-gtm-module";
//import CookiePopup from "cookie/CookiePopup";
//import CenterPopup from "popup/comp/center/CenterPopup";
import {LANG_EN, LANG_EN_US, LANG_KO_KR} from "language/langutil";
import {DisplayPopupItem, IfDisplayPopupItem} from "models/IfDisplayPopupItem";
//import BottomPopup from "./popup/comp/bottom/BottomPopup";
import {isExistSessionLanguage} from "util/sessionutil";
//import PartnerQnaPagePopup from 'communitypage/partner/qna/PartnerQnaPagePopup';
//import AssetInsight from "servicePage/asset/AssetInsight";

const AssetInsight = React.lazy(() => import("servicePage/asset/AssetInsight"));

const CenterPopup = React.lazy(() => import("popup/comp/center/CenterPopup"));
const BottomPopup = React.lazy(() => import("./popup/comp/bottom/BottomPopup"));
const CookiePopup = React.lazy(() => import( "cookie/CookiePopup"));
const PartnerQnaPagePopup = React.lazy(() => import('communitypage/partner/qna/PartnerQnaPagePopup'));

const DispPreRegist = React.lazy(() => import("./pages/disp/DispPreRegist"));
const TestLayout = React.lazy(() => import( "./tests/TestLayout").then(module => ({default: module.TestLayout})));
const UnsupportedBrowserPage = React.lazy(() => import("./header/UnsupportedBrowserPage"));
const NotFoundPage = React.lazy(() => import("./NotFoundPage"));
const NaverLoginCallBackPage = React.lazy(() => import('./header/NaverLoginCallBackPage'));

const RequireMemberLoginPage = React.lazy(() => import("./login/RequireMemberLoginPage"));
const RequireEmployeeLoginPage = React.lazy(() => import("./login/RequireEmployeeLoginPage"));
const RequirePartnerLoginPage = React.lazy(() => import("./login/RequirePartnerLoginPage"));

const SocialSignupPage = React.lazy(() => import("./header/SocialSignupPage"));
const ResetPasswordPage = React.lazy(() => import("./header/ResetPasswordPage"));

const AdminEventDocumentListPage = React.lazy(() => import("./borispage/event/AdminEventDocumentListPage"));
const AdminNewEventDocumentPage = React.lazy(() => import("./borispage/event/AdminNewEventDocumentPage"));
const AdminEventDocumentListReviewPage = React.lazy(() => import("./borispage/event/AdminEventDocumentListReviewPage"));
const EventReviewPopupPage = React.lazy(() => import("./borispage/event/EventReviewPopupPage"));

const ProductPage = React.lazy(() => import("./productpage/ProductPage"));

const SearchBoxPopup2 = React.lazy(() => import("search2/SearchBoxPopup2"));

const AllSearchPage2 = React.lazy(() => import("search2/AllSearchPage2"));

const ProductPagePopup = React.lazy(() => import("./productpage/ProductPagePopup"));
const VerifyEmailPage = React.lazy(() => import("./header/VerifyEmailPage"));
const SolutionPage = React.lazy(() => import("./solutionpage/SolutionPage"));

const SolutionPagePopup = React.lazy(() => import("./solutionpage/SolutionPagePopup"));
const ProfilePage = React.lazy(() => import("./mypage/ProfilePage"));

const CommunityPage = React.lazy(() => import("./communitypage/CommunityPage"));
const CmmntyDocWritePage = React.lazy(() => import("docwrite/community/CmmntyDocWritePage"));

const QnaPagePopup = React.lazy(() => import("communitypage/qna/popup/QnaPagePopup"));
const BlogPagePopup = React.lazy(() => import("communitypage/blog/BlogPagePopup"));
const BoardPagePopup = React.lazy(() => import("./communitypage/board/BoardPagePopup"));

const AdminPage2 = React.lazy(() => import("borispage/AdminPage2"));

const ServicePage = React.lazy(() => import("./servicePage/ServicePage"));
const SamplePagePopup = React.lazy(() => import("servicePage/sample/SamplePagePopup"));
const SwServicePagePopup = React.lazy(() => import("servicePage/swService/SwServicePagePopup"));
const AdminSwRegisterPage = React.lazy(() => import("./borispage/admintab/swtab/AdminSwRegisterPage"));

const MainPage = React.lazy(() => import( "./mainpage/MainPage"));

const ProductSelectorPubl = React.lazy(() => import( "pages/prodselector/publ/ProductSelectorPubl"));
const ProductSelector = React.lazy(() => import( "pages/prodselector/ProductSelector"));
const EnergySave = React.lazy(() => import("servicePage/energySave/EnergySave"));
const SwService = React.lazy(() => import("servicePage/swService/SwServicePage"));

const AssetPage = React.lazy(() => import("asset/AssetPage"));
//const AssetMainPage = React.lazy(() => import("asset/AssetMainPage"));
const AssetDashboardPage = React.lazy(() => import("asset/pages/dashboard/AssetDashboardPage"));
const AssetAlarmPage = React.lazy(() => import("asset/pages/alarm/AssetAlarmPage"));
const AssetHelpPage = React.lazy(() => import("asset/pages/help/AssetHelpPage"));
const AssetMonitorPage = React.lazy(() => import("asset/pages/monitor/AssetMonitorPage"));
const AssetMonitorProjectPage = React.lazy(() => import("asset/pages/monitorproject/AssetMonitorProjectPage"));
const AssetMonitorDevicePage = React.lazy(() => import("asset/pages/monitordevice/AssetMonitorDevicePage"));
const AssetProfilePage = React.lazy(() => import("asset/pages/profile/AssetProfilePage"));
const AssetReportPage = React.lazy(() => import("asset/pages/report/AssetReportPage"));
const AssetSettingsPage = React.lazy(() => import("asset/pages/settings/AssetSettingsPage"));
const AssetTrendPage = React.lazy(() => import("asset/pages/trend/AssetTrendPage"));
const AssetLoginRequired = React.lazy(() => import("asset/pages/loginrequired/AssetLoginRequired"));
const AssetJwtTokenPage = React.lazy(() => import("asset/pages/jwt/AssetJwtTokenPage"));
const AssetTestsLayout = React.lazy(() => import("asset/pages/tests/AssetTestsLayout"));
const AssetParameterPage = React.lazy(() => import("asset/pages/parameter/AssetParameterPage"));

interface AppProps {
  languageService: LanguageService;
  categoryRepository: CategoryRepository;
  categoryStore: CategoryStore;
  ssqDataRepository: SsqDataRepository;
  accountService: AccountService;
  searchRepository2: SearchRepository2;
  loginService: LoginService;
  localStorageWrapper: LocalStorageWrapper;
  notificationService: NotificationService;
}


const App = (appProps: AppProps) => {
  const [showSearchBoxPopup, setShowSearchBoxPopup] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [isSearchDocuments, setIsSearchDocuments] = useState(true);
  const [isSearchInResult, setIsSearchInResult] = useState(false);
  const [isLoginFailPopup, setIsLoginFailPopup] = useState(false);
  const svrConf = useSelector((state: any) => state.main.svrConf as IfSvrConf);
  const isLoginPopup = useSelector((state: any) => state.main.isLoginPopup);
  const dispatch = useDispatch();

  const [seq, setSeq] = useState(0);
  const [toastType, setToastType] = useState("");

  const displayPopupItem = useSelector((state: any) => state.main.displayPopupItem);

  const setDisplayPopupItem = React.useCallback((b: IfDisplayPopupItem) => {
    dispatch({type: ACTION_TYPES.MAIN.DISPLAY_POPUP_ITEM, payload: b});
  }, [dispatch]);

  const setDiplayBottomPopup = React.useCallback((b: boolean) => {
    dispatch({type: ACTION_TYPES.MAIN.DISPLAY_BOTTOM_POPUP, payload: b});
  }, [dispatch]);


  const googleAnalyticsAccess = () => {
    const tagId = cookieutil.getGoogleTagId(window.location.host);

    const tagManagerArgs = {
      gtmId: tagId
    };

    TagManager.initialize(tagManagerArgs);
  };


  const setIsLoginPopup = (b: boolean) => {
    dispatch({type: ACTION_TYPES.MAIN.IS_LOGIN_POPUP, payload: b});
  };

  if ((window.location.host.includes("sol.ls-electric.com")) && (window.location.protocol === 'http:')) {
    window.location.href = window.location.href.replace('http:', 'https:');
  }

  const displaySearchPopup = () => {
    setShowSearchBoxPopup(true);
  };

  const closeSearchPopup = () => {
    setShowSearchBoxPopup(false);
  };

  const displayLoginPopup = () => {
    setIsLoginPopup(true);
  };

  const displayLoginFailPopup = () => {
    setIsLoginFailPopup(true);
  };

  useEffect(() => {
    googleAnalyticsAccess();
    const popupItem = new DisplayPopupItem();
    const path = location.pathname;
    const lang = svcs.svcLang.lang();

    //login
    if (/^\/\w\w\/\w\w\/login/.test(path)) {
      setIsLoginPopup(true);
    } else {
      //intro
      if (/^\/\w\w\/\w\w\/main/.test(path) || path == '/') {
        if (cookieutil.isAcceptFunctionConsentCookie(lang)) {
          if (!window.localStorage.getItem("firstVisitedTime")) popupItem.intro = true;
        }
      }

      if (lang != LANG_KO_KR)
        if (!cookieutil.countrySpecificCookieVerification(lang)) popupItem.cookie = true;

      if (!isExistSessionLanguage('DISPLAY_CENTER_SESSION', lang)) {
        popupItem.center = true;
      }

      if (!isExistSessionLanguage('DISPLAY_ASSET_SESSION', lang)) {
        popupItem.asset = true;
      }

      setDisplayPopupItem(popupItem);

      if (!isExistSessionLanguage('DISPLAY_BOTTOM_SESSION', lang)) {
        setDiplayBottomPopup(true);
      }

    }
  }, []);


  /*
  https://velog.io/@ww8007/HTML-lang-속성에-대하여
  https://homzzang.com/b/html-156
   */
  const lang = svcs.svcLang.lang(); // ( 언어-국가 ) 로 처리해도 됨.
  return (
    <>
      <HelmetProvider>
        <GlobalStyle/>
        <Helmet htmlAttributes={{lang}}>
          {
            [LANG_EN, LANG_EN_US].includes(lang) ? <>
              <meta content="The only website needed for anything related to LS Electric Automation Products" name="description"/>
              <meta
                content="SOLUTION SQUARE, Smart Factory, LS ELECTRIC, Smart Factory, Automation equipment information, Platform, PLC, Drive, Inverter, HMI, Q&A, Energy Saving, Community, Engineering, Automation, Automation material, LSIS"
                name="keywords"/>
              <meta content="Solution Square" property="og:title"/>
              <meta content="The only website needed for anything related to LS Electric Automation Products"
                property="og:description"/>
            </> : <>
              <meta content="LS ELECTRIC 자동화 엔지니어링의 모든 것 (LS 산전)" name="description"/>
              <meta
                content="솔루션스퀘어, SOLUTION SQUARE, 스마트팩토리, LS ELECTRIC 스마트팩토리, 스마트공장, 자동화 기기 정보 제공, 플랫폼, PLC, Drive, 드라이브, 인버터, HMI, 샘플, 엔지니어링, 자동화, 자동화 자료, Automation"
                name="keywords"/>
              <meta content="Solution Square - 솔루션스퀘어" property="og:title"/>
              <meta content="LS ELECTRIC 자동화 엔지니어링의 모든 것 (LS 산전)" property="og:description"/>
            </>
          }

        </Helmet>
        <SWRConfig value={{fetcher: swrFetcher, revalidateOnFocus: false}}>
          <AppContainer>
            <Router>
              {showSearchBoxPopup && <React.Suspense fallback={<div></div>}><SearchBoxPopup2
                languageService={appProps.languageService}
                categoryRepository={appProps.categoryRepository}
                onClickCloseButton={closeSearchPopup.bind(this)}
                searchRepository2={appProps.searchRepository2}
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                searchCheckbox={isSearchDocuments}
                setSearchCheckbox={setIsSearchDocuments}
                localStorageWrapper={appProps.localStorageWrapper}
                defaultFocusedInput
                nonDisplayRelated={true}
              /></React.Suspense>}
              {showSearchBoxPopup && <Overlay aria-label={"search-popup-overlay"} onClick={() => {
                closeSearchPopup();
              }}/>}
              {displayPopupItem.cookie && [LANG_EN, LANG_EN_US].includes(lang) && <React.Suspense fallback={null}><CookiePopup
                displayPopupItem={displayPopupItem}
                setDisplayPopupItem={setDisplayPopupItem.bind(this)}
              /></React.Suspense>}


              <BodyPages
                {...appProps}
                svrConf={svrConf}
                setShowSearchBoxPopup={setShowSearchBoxPopup.bind(this)}
                showSearchBoxPopup={showSearchBoxPopup}
                searchInput={searchInput}
                searchCheckbox={isSearchDocuments}
                searchInResultCheckbox={isSearchInResult}
                setSearchInResultCheckbox={setIsSearchInResult}
                setSearchCheckbox={setIsSearchDocuments.bind(this)}
                displaySearchPopup={displaySearchPopup.bind(this)}
                isLoginPopup={isLoginPopup}
                isLoginFailPopup={isLoginFailPopup}
                displayLoginPopup={displayLoginPopup.bind(this)}
                displayLoginFailPopup={displayLoginFailPopup.bind(this)}
                closeSearchPopup={closeSearchPopup.bind(this)}
                setToastType={setToastType.bind(this)}
                setSeq={setSeq.bind(this)}
                displayPopupItem={displayPopupItem}
                setDisplayPopupItem={setDisplayPopupItem}
              />
            </Router>
          </AppContainer>
        </SWRConfig>
      </HelmetProvider>
    </>
  );
};

export default App;

// @ts-ignore
// @ts-ignore
export const BodyPages = ({
  svrConf,
  languageService,
  categoryRepository,
  categoryStore,
  ssqDataRepository,
  accountService,
  searchRepository2,
  searchInput,
  setShowSearchBoxPopup,
  showSearchBoxPopup,
  searchCheckbox,
  setSearchCheckbox,
  displaySearchPopup,
  loginService,
  notificationService,
  localStorageWrapper,
  searchInResultCheckbox,
  setSearchInResultCheckbox,
  isLoginPopup,
  isLoginFailPopup,
  displayLoginPopup,
  displayLoginFailPopup,
  closeSearchPopup,
  setToastType,
  setSeq,
  displayPopupItem,
  setDisplayPopupItem
}: any) => <>
  <Switch>
    <RouteWithHeader path={["/", "/:country/:lang/main", "/:country/:lang/login"]}
      exact
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}
      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}
    >
      <MainPage
        searchRepository2={searchRepository2}
        languageService={languageService}
        categoryRepository={categoryRepository}
        categoryStore={categoryStore}
        setShowSearchBoxPopup={setShowSearchBoxPopup}
        showSearchBoxPopup={showSearchBoxPopup}
        searchInput={searchInput}
        searchCheckbox={searchCheckbox}
        setSearchCheckbox={setSearchCheckbox}
        localStorageWrapper={localStorageWrapper}
        isLoginPopup={isLoginPopup}
        displayPopupItem={displayPopupItem}
        setDisplayPopupItem={setDisplayPopupItem}
      />
    </RouteWithHeader>

    <RouteWithHeader path={["/:country/:lang/product/category/:categoryId", "/product/category/:categoryId"]}
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}
      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}
    >
      <ProductPage
        displayLoginPopup={displayLoginPopup}
        languageService={languageService}
        categoryRepository={categoryRepository}
        ssqDataRepository={ssqDataRepository}
        categoryStore={categoryStore}
      />
    </RouteWithHeader>

    <RouteWithHeader path={["/:country/:lang/solution/category/:categoryId", "/solution/category/:categoryId"]}
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}
      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}
    >
      <SolutionPage
        languageService={languageService}
        categoryRepository={categoryRepository}
        ssqDataRepository={ssqDataRepository}
      />
    </RouteWithHeader>


    <RouteWithHeader path={["/:country/:lang/productselector", "/productselector"]}
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}
      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}
    >
      <ProductSelector
        languageService={languageService}
        localStorageWrapper={localStorageWrapper}
      />
    </RouteWithHeader>
    <RouteWithHeader path={["/:country/:lang/service/energysave", "/service/energysave"]}
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}
      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}
    >
      <EnergySave
        languageService={languageService}
      />
    </RouteWithHeader>
    <RouteWithHeader path={["/:country/:lang/service/assetinsight", "/service/assetinsight"]}
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}
      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}
    >
      <AssetInsight
        languageService={languageService}
      />
    </RouteWithHeader>

    <

      RouteWithHeader path={["/:country/:lang/swservice", "/swservice"]}
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}
      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}
    >
      <SwService languageService={languageService}
        categoryRepository={categoryRepository}
        accountService={accountService}
        ssqDataRepository={ssqDataRepository}
        displayLoginPopup={displayLoginPopup}
      />
    </RouteWithHeader>

    <RouteWithHeader path={["/productSelectorPubl"]}
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}
      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}
    >
      <ProductSelectorPubl/>
    </RouteWithHeader>


    <RouteWithHeader path={"/borisPage"}
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}
      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}
    >
      <AdminWrap className={"Admin_wrap"}>
        <AdminPage2
          languageService={languageService}
          accountService={accountService}
          categoryRepository={categoryRepository}
          ssqDataRepository={ssqDataRepository}
          closeSearchPopup={closeSearchPopup}
        />
      </AdminWrap>
    </RouteWithHeader>
    <RouteWithHeader path={"/boris/sw/new/:originalSwId"}
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}
      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}
    >
      <AdminWrap className={"Admin_wrap"}>
        <AdminSwRegisterPage
          languageService={languageService}
          ssqDataRepository={ssqDataRepository}
          categoryRepository={categoryRepository}
          accountService={accountService}
          notificationService={notificationService}
          categoryStore={categoryStore}
        />
      </AdminWrap>
    </RouteWithHeader>
    <RouteWithHeader path={"/boris/sw/new"}
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}
      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}
    >
      <AdminWrap className={"Admin_wrap"}>
        <AdminSwRegisterPage
          languageService={languageService}
          ssqDataRepository={ssqDataRepository}
          categoryRepository={categoryRepository}
          accountService={accountService}
          notificationService={notificationService}
          categoryStore={categoryStore}
        />
      </AdminWrap>
    </RouteWithHeader>

    <RouteWithHeader path={
      ["/boris/new/community/:orgDocId",
        "/:country/:lang/community/new/:orgDocId",
        "/:country/:lang/community/temp/:tempId/:docId",
        "/:country/:lang/community/approval/:docId",
        "/community/new/:orgDocId",
        "/community/temp/:tempId/:docId",
        "/community/approval/:docId",
        "/community/copy/:copyDocId",
      ]}
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}
      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}
    >
      <AdminWrap className={"Admin_wrap"}>
        <CmmntyDocWritePage/>
      </AdminWrap>
    </RouteWithHeader>


    <RouteWithHeader path={["/newSearch", "/:country/:lang/newSearch"]}
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}
      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}
    >
      <AllSearchPage2
        languageService={languageService}
        categoryRepository={categoryRepository}
        searchRepository2={searchRepository2}
        ssqDataRepository={ssqDataRepository}
        localStorageWrapper={localStorageWrapper}
        searchCheckbox={searchCheckbox}
        setSearchCheckbox={setSearchCheckbox}
        searchInResultCheckbox={searchInResultCheckbox}
        setSearchInResultCheckbox={setSearchInResultCheckbox}
      />
    </RouteWithHeader>


    <RouteWithHeader path={["/:country/:lang/product/document/:documentId", "/product/document/:documentId"]}
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}
      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}
    >
      <ProductPagePopup
        accountService={accountService}
        languageService={languageService}
        categoryRepository={categoryRepository}
        ssqDataRepository={ssqDataRepository}
        categoryStore={categoryStore}
      />
    </RouteWithHeader>

    <RouteWithHeader path={["/:country/:lang/solution/document/:documentId", "/solution/document/:documentId"]}
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}
      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}
    >
      <SolutionPagePopup
        accountService={accountService}
        languageService={languageService}
        categoryRepository={categoryRepository}
        ssqDataRepository={ssqDataRepository}
        categoryStore={categoryStore}
      />
    </RouteWithHeader>


    <RouteWithHeader
      path={["/:country/:lang/community/partner/qna/document/:documentId", "/community/partner/qna/document/:documentId"]}
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}
      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}
    >
      <PartnerQnaPagePopup
        accountService={accountService}
        languageService={languageService}
        categoryRepository={categoryRepository}
        ssqDataRepository={ssqDataRepository}
        categoryStore={categoryStore}
        loginService={loginService}
        displayLoginPopup={displayLoginPopup}
        displayLoginFailPopup={displayLoginFailPopup}
        notificationService={notificationService}
      />
    </RouteWithHeader>

    <RouteWithHeader
      path={["/:country/:lang/community/qna/document/:documentId", "/community/qna/document/:documentId",
        "/:country/:lang/community/rnd/qna/document/:documentId", "/community/rnd/qna/document/:documentId"]}
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}
      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}
    >
      <QnaPagePopup
        accountService={accountService}
        languageService={languageService}
        categoryRepository={categoryRepository}
        ssqDataRepository={ssqDataRepository}
        categoryStore={categoryStore}
        loginService={loginService}
        displayLoginPopup={displayLoginPopup}
        displayLoginFailPopup={displayLoginFailPopup}
        notificationService={notificationService}
      />
    </RouteWithHeader>

    <RouteWithHeader
      path={["/:country/:lang/community/blog/document/:documentId", "/community/blog/document/:documentId",
        "/:country/:lang/community/rnd/notice/document/:documentId", "/community/rnd/notice/document/:documentId",
        "/:country/:lang/community/partner/notice/document/:documentId", "/community/partner/notice/document/:documentId"
      ]}
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}
      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}
    >
      <BlogPagePopup
        accountService={accountService}
        languageService={languageService}
        categoryRepository={categoryRepository}
        ssqDataRepository={ssqDataRepository}
        categoryStore={categoryStore}
        loginService={loginService}
        displayLoginPopup={displayLoginPopup}
        displayLoginFailPopup={displayLoginFailPopup}
        notificationService={notificationService}
        openToast={(type) => {
          setToastType(type);
          setSeq + 1;
        }}
      />
    </RouteWithHeader>

    <RouteWithHeader
      path={["/:country/:lang/community/board/document/:documentId", "/community/board/document/:documentId",
        "/:country/:lang/community/rnd/talk/document/:documentId", "/community/rnd/talk/document/:documentId"]}
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}
      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}
    >
      <BoardPagePopup
        accountService={accountService}
        languageService={languageService}
        categoryRepository={categoryRepository}
        ssqDataRepository={ssqDataRepository}
        categoryStore={categoryStore}
        loginService={loginService}
        displayLoginPopup={displayLoginPopup}
        displayLoginFailPopup={displayLoginFailPopup}
        notificationService={notificationService}
        openToast={(type) => {
          setToastType(type);
          setSeq + 1;
        }}
      />
    </RouteWithHeader>


    <RouteWithHeader path={["/:country/:lang/community/:subMenu", "/community/:subMenu"]}
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}
      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}
    >
      <CommunityPage
        accountService={accountService}
        languageService={languageService}
        categoryRepository={categoryRepository}
        ssqDataRepository={ssqDataRepository}
        categoryStore={categoryStore}
        displayLoginPopup={displayLoginPopup}
      />
    </RouteWithHeader>


    <RouteWithHeader
      path={["/:country/:lang/service/sample/document/:documentId", "/service/sample/document/:documentId"]}
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}
      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}
    >
      <SamplePagePopup
        displayLoginPopup={displayLoginPopup}
        displayLoginFailPopup={displayLoginFailPopup}
        accountService={accountService}
        languageService={languageService}
        categoryRepository={categoryRepository}
        ssqDataRepository={ssqDataRepository}
        categoryStore={categoryStore}
        loginService={loginService}
        notificationService={notificationService}
        openToast={(type) => {
          setToastType(type);
          setSeq + 1;
        }}
      />
    </RouteWithHeader>

    <RouteWithHeader
      path={["/:country/:lang/service/software/document/:documentId", "/service/software/document/:documentId"]}
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}
      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}
    >
      <SwServicePagePopup
        displayLoginPopup={displayLoginPopup}
        accountService={accountService}
        languageService={languageService}
        categoryRepository={categoryRepository}
        ssqDataRepository={ssqDataRepository}
        categoryStore={categoryStore}
        loginService={loginService}
        displayLoginFailPopup={displayLoginFailPopup}
        notificationService={notificationService}
        openToast={(type) => {
          setToastType(type);
          setSeq + 1;
        }}
      />
    </RouteWithHeader>

    <RouteWithHeader path={["/:country/:lang/service/:subMenu", "/service/:subMenu"]}
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}
      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}
    >

      <ServicePage
        accountService={accountService}
        languageService={languageService}
        categoryRepository={categoryRepository}
        ssqDataRepository={ssqDataRepository}
        categoryStore={categoryStore}
        displayLoginPopup={displayLoginPopup}
      />

    </RouteWithHeader>


    <RouteWithHeader path={["/:country/:lang/myPage", "/myPage"]}
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}
      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}
    >
      <ProfilePage
        accountService={accountService}
        languageService={languageService}
        ssqDataRepository={ssqDataRepository}
        closeSearchPopup={closeSearchPopup}
        loginService={loginService}
        notificationService={notificationService}
        displayLoginPopup={displayLoginPopup}
      />
    </RouteWithHeader>

    <RouteWithSuspense path={"/:country/:lang/rd/:urlGroup/:urlId"}>
      <UrlLinkPage/>
    </RouteWithSuspense>

    <AssetLayout path={["/asset/dashboard", "/:country/:lang/asset/dashboard"]} exact><AssetDashboardPage/></AssetLayout>
    <AssetLayout path={["/asset/alarm", "/:country/:lang/asset/alarm"]} exact><AssetAlarmPage/></AssetLayout>
    <AssetLayout path={["/asset/help", "/:country/:lang/asset/help", "/asset/help/:documentId", "/:country/:lang/asset/help/:documentId"]}
      exact><AssetHelpPage guideUrl={false}/></AssetLayout>
    <AssetLayout path={["/asset/guide", "/:country/:lang/asset/guide", "/asset/guide/:documentId", "/:country/:lang/asset/guide/:documentId"]}
      exact guestAccess={true}><AssetHelpPage guideUrl={true}/></AssetLayout>
    <AssetLayout path={["/asset/monitor", "/:country/:lang/asset/monitor"]} exact><AssetMonitorPage/></AssetLayout>
    <AssetLayout path={["/asset/monitorproject/:projectId", "/:country/:lang/asset/monitorproject/:projectId"]} exact>
      <AssetMonitorProjectPage/></AssetLayout>
    <AssetLayout path={["/asset/monitordevice/:deviceId", "/:country/:lang/asset/monitordevice/:deviceId"]} exact>
      <AssetMonitorDevicePage/></AssetLayout>
    <AssetLayout path={["/asset/profile", "/:country/:lang/asset/profile"]} exact><AssetProfilePage/></AssetLayout>
    <AssetLayout path={["/asset/report", "/:country/:lang/asset/report"]} exact><AssetReportPage/></AssetLayout>
    <AssetLayout path={["/asset/settings", "/:country/:lang/asset/settings"]} exact><AssetSettingsPage/></AssetLayout>
    <AssetLayout path={["/asset/trend", "/:country/:lang/asset/trend"]} exact><AssetTrendPage/></AssetLayout>
    <AssetLayout path={["/asset/parameter", "/:country/:lang/asset/parameter"]} exact><AssetParameterPage/></AssetLayout>

    {/*<AssetBlankLayout path={["/asset/loginrequired", "/:country/:lang/asset/loginrequired"]} exact><AssetLoginRequired/></AssetBlankLayout>*/}
    <AssetBlankLayout path={["/asset/jwttoken", "/:country/:lang/asset/jwttoken"]} exact><AssetJwtTokenPage/></AssetBlankLayout>

    {svrConf?.activeProfile !== 'prod' ? <AssetBlankLayout path={["/asset/tests"]}><AssetTestsLayout/></AssetBlankLayout> :
      <AssetBlankLayout path={["/asset/tests"]}>
        <div/>
      </AssetBlankLayout>}

    <RouteWithHeader path={["/:country/:lang/asset", "/asset"]}
      exact
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}
      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}>
      <AssetPage
        accountService={accountService}
        languageService={languageService}
        loginService={loginService}
        displayLoginPopup={displayLoginPopup}
      />
    </RouteWithHeader>
    <RouteWithoutHeader path={["/asset/loginrequired", "/:country/:lang/asset/loginrequired"]}
      exact
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}
      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}
    >
      <AssetLoginRequired
        languageService={languageService}
        accountService={accountService}
        displayLoginPopup={displayLoginPopup}
        displayLoginFailPopup={displayLoginFailPopup}
      />
    </RouteWithoutHeader>

    <RouteWithHeader path={["/requireMemberLogin", "/:country/:lang/requireMemberLogin"]}
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}
      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}
    >
      <RequireMemberLoginPage languageService={languageService}
        accountService={accountService}
        displayLoginPopup={displayLoginPopup}
        displayLoginFailPopup={displayLoginFailPopup}
      />
    </RouteWithHeader>
    <RouteWithHeader path={["/requireEmployeeLogin", "/:country/:lang/requireEmployeeLogin"]}
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}
      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}
    >
      <RequireEmployeeLoginPage languageService={languageService}
        accountService={accountService}
        displayLoginPopup={displayLoginPopup}
        displayLoginFailPopup={displayLoginFailPopup}
      />

    </RouteWithHeader>

    <RouteWithHeader path={["/requirePartnerLogin", "/:country/:lang/requirePartnerLogin"]}
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}
      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}
    >
      <RequirePartnerLoginPage languageService={languageService}
        accountService={accountService}
        displayLoginPopup={displayLoginPopup}
        displayLoginFailPopup={displayLoginFailPopup}
      />

    </RouteWithHeader>
    <RouteWithHeader path={"/boris/ideaEventPage"}
      exact
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}
      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}
    >
      <AdminEventDocumentListPage ssqDataRepository={ssqDataRepository}
        accountService={accountService}
      />
    </RouteWithHeader>

    <RouteWithHeader path={"/boris/ideaEventPage/RestrictedReviewerOnly"}
      exact
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}

      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}
    >
      <AdminEventDocumentListReviewPage ssqDataRepository={ssqDataRepository}
        accountService={accountService}
      />
    </RouteWithHeader>

    <RouteWithHeader path={"/boris/event/new/document/:originalDocumentId"}
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}
      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}
    >
      <AdminWrap className={"Admin_wrap"}>
        <AdminNewEventDocumentPage ssqDataRepository={ssqDataRepository}
          accountService={accountService}
          languageService={languageService}
          categoryRepository={categoryRepository}
          categoryStore={categoryStore}
        />
      </AdminWrap>
    </RouteWithHeader>

    <RouteWithHeader path={"/boris/event/document/:originalDocumentId"}
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}
      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}
    >
      <EventReviewPopupPage
        accountService={accountService}
        languageService={languageService}
        ssqDataRepository={ssqDataRepository}
      />
    </RouteWithHeader>

    <RouteWithSuspense path={["/disp/dispPreRegist", "/:country/:lang/disp/dispPreRegist"]} exact>
      <DispPreRegist/>
    </RouteWithSuspense>


    <RouteWithSuspense path={"/verifyEmail/:email"}>
      <VerifyEmailPage
        accountService={accountService}
        languageService={languageService}
      />
    </RouteWithSuspense>

    <RouteWithSuspense path={"/resetPassword/:email/:resetPassword"}
    >
      <ResetPasswordPage
        accountService={accountService}
        languageService={languageService}
      />
    </RouteWithSuspense>

    <RouteWithSuspense path={"/loginCallback"}>
      <LoginCallbackPage/>
    </RouteWithSuspense>

    <RouteWithSuspense path={"/naverLoginCallback"}>
      <NaverLoginCallBackPage
        languageService={languageService}
        loginService={loginService}
        accountService={accountService}
      />
    </RouteWithSuspense>

    <RouteWithSuspense path={"/socialSignupPage"}>
      <SocialSignupPage
        accountService={accountService}
      />
    </RouteWithSuspense>
    <RouteWithSuspense path={"/unsupportedBrowserPage"}>
      <UnsupportedBrowserPage
        languageService={languageService}
      />
    </RouteWithSuspense>
    {svrConf?.activeProfile !== 'prod' ? <RouteWithSuspense path="/tests"><TestLayout/></RouteWithSuspense> : <RouteWithSuspense path="/tests">
      <div/>
    </RouteWithSuspense>}


    <RouteWithHeader path={"*"}
      accountService={accountService}
      languageService={languageService}
      categoryRepository={categoryRepository}
      categoryStore={categoryStore}
      displaySearchPopup={displaySearchPopup}
      loginService={loginService}
      notificationService={notificationService}
      isLoginPopup={isLoginPopup}
      isLoginFailPopup={isLoginFailPopup}
      localStorageWrapper={localStorageWrapper}
      closeSearchPopup={closeSearchPopup}
      showSearchBoxPopup={showSearchBoxPopup}
    >
      <NotFoundPage languageService={languageService}/>
    </RouteWithHeader>

  </Switch>

</>;

interface RouteProps {
  path: string | string[];
  exact?: boolean;
  accountService: AccountService;
  languageService: LanguageService;
  categoryRepository: CategoryRepository;
  categoryStore: CategoryStore;
  displaySearchPopup: Function;
  loginService: LoginService;
  notificationService: NotificationService;
  children: any;
  isLoginPopup: boolean;
  isLoginFailPopup: boolean;
  closeSearchPopup: Function;
  localStorageWrapper: LocalStorageWrapper;
  showSearchBoxPopup: boolean;
}

export const RouteWithHeader = (props: RouteProps) => {
  const displayPopupItem = useSelector((state: any) => state.main.displayPopupItem);
  const displayBottomPopup = useSelector((state: any) => state.main.displayBottomPopup);


  return <Route path={props.path}
    exact={!!props.exact}
    render={() => {
      return <>
        <Header accountService={props.accountService}
          languageService={props.languageService}
          categoryRepository={props.categoryRepository}
          categoryStore={props.categoryStore}
          onClickSearchButton={props.displaySearchPopup}
          loginService={props.loginService}
          notificationService={props.notificationService}
          isLoginPopup={props.isLoginPopup}
          isLoginFailPopup={props.isLoginFailPopup}
          localStorageWrapper={props.localStorageWrapper}
          closeSearchPopup={props.closeSearchPopup}
          showSearchBoxPopup={props.showSearchBoxPopup}
        />

        <React.Suspense fallback={<div></div>}>
          <BodyContainer>
            {React.Children.map(props.children, child => React.cloneElement(child))}
          </BodyContainer>
          <Footer languageService={props.languageService}/>
        </React.Suspense>
        {displayBottomPopup && <React.Suspense fallback={null}><BottomPopup languageService={props.languageService}/></React.Suspense>}
        {displayPopupItem.center && !displayPopupItem.cookie && !displayPopupItem.intro &&
          <React.Suspense fallback={null}><CenterPopup languageService={props.languageService}/></React.Suspense>}
      </>;
    }}/>;
};

export const RouteWithoutHeader = (props: RouteProps) => {
  return <Route path={props.path}
    exact={!!props.exact}
    render={() => {
      return <>
        <Header
          hideHeader={true}
          accountService={props.accountService}
          languageService={props.languageService}
          categoryRepository={props.categoryRepository}
          categoryStore={props.categoryStore}
          onClickSearchButton={props.displaySearchPopup}
          loginService={props.loginService}
          notificationService={props.notificationService}
          isLoginPopup={props.isLoginPopup}
          isLoginFailPopup={props.isLoginFailPopup}
          localStorageWrapper={props.localStorageWrapper}
          closeSearchPopup={props.closeSearchPopup}
          showSearchBoxPopup={props.showSearchBoxPopup}
        />

        <React.Suspense fallback={<div></div>}>
          <BodyContainer>
            {React.Children.map(props.children, child => React.cloneElement(child))}
          </BodyContainer>
        </React.Suspense>
        {/*<EventBanner languageService={props.languageService}/>*/}
      </>;
    }}/>;
};

export const RouteWithSuspense = (props: { exact?: boolean, path: string | string[], children }) => {
  return <Route path={props.path}
    exact={!!props.exact}
    render={() => {
      return <React.Suspense fallback={<div></div>}>
        {React.Children.map(props.children, child => React.cloneElement(child))}
      </React.Suspense>;
    }}/>;
};

export const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    width: 100%;
    font-size: 62.5%;
    ${fontFamilyMedium};
    color: #2A2C2F;
  }

  //ios notch 영역 여백
  body {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    padding: constant(safe-area-inset-top) constant(safe-area-inset-right) constant(safe-area-inset-bottom) constant(safe-area-inset-left);
  }

  .visuallyhidden {
    border: 0 none;
    clip: rect(0px, 0px, 0px, 0px);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }


  * {
    padding: 0;
    margin: 0;
    outline: 0;
    border: 0;
    background: transparent;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    letter-spacing: -0.2px;
  }


  button, input[type="radio"], select {
    cursor: pointer;
  }

  select {
    height: 3.2rem;
    color: #2A2C2F;

    :disabled {
      cursor: inherit;
    }
  }

  .swiper-button-arrow:after, .swiper-button-next:after, .swiper-button-prev:after {
    display: none;
  }

  *::-webkit-scrollbar {
    width: 9px;
    height: 9px;

    &-thumb {
      background-color: #7783A1;
      border-radius: 6px;
    }

    &-track {
      background-color: transparent;
    }

    &-button {
      width: 0;
      height: 0;
    }
  }

  .container {
    max-width: 120rem;
    margin: 0 auto;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 600 !important;
  }

  input[type="text"], input[type="password"] {
    -webkit-appearance: none;
    ${fontFamilyMedium};
  }

  textarea {
    resize: none;
    ${fontFamilyMedium};
  }

  @media screen and (max-width: 1199px) {
    .container {
      max-width: 100% !important;
    }
  }

  @media screen and (max-width: 764px) {

    html {
      font-size: 9px;
    }
  }
`;

const AppContainer = styled.div`
  position: relative;
  height: 100%;

  .mobile {
    display: none
  }

  &:has(.bottom-popup) .fixed-ft-btn {
    bottom: 12.8rem;
  }

  @media screen and (max-width: 1199px) {
    .mobile {
      display: block
    }

    .non_mobile {
      display: none !important;
    }
  }

  @media screen and (max-width: 768px) {
    &:has(.commonity-footer-menu.active) {
      .fixed-ft-btn {
        bottom: 9rem;
      }
    }

    &:has(.bottom-popup) .fixed-ft-btn {
      bottom: calc(19.1rem + 2.8rem);
      right: 2.4rem;
    }
  }
`;

const BodyContainer = styled.div`
  position: relative;

  button {
    ${fontFamilyRegular};
  }

  .batch-button {
    cursor: pointer;
    color: white;
    border-radius: 4px;
    width: 11.2rem;
    height: 4rem;
    line-height: 4rem;
    font-size: 1.3rem;
    background: #7783A1;
    border: solid 1px #7783A1;
    text-align: center;

    ${fontFamilyBold}
    + .batch-button {
      margin-left: .8rem;
    }

    :hover {
      background: #6B758E;
    }

    :active {
      background: #596278;
    }
  }

  .white-button {
    cursor: pointer;
    color: #2A2C2F;
    border-radius: 4px;
    width: 11.2rem;
    height: 4rem;
    line-height: 4rem;
    font-size: 1.3rem;
    background: white;
    border: solid 1px #C7D6EA;
    text-align: center;

    ${fontFamilyBold}
  }

  .post-button {
    cursor: pointer;
    color: white;
    width: 11.2rem;
    height: 4rem;
    line-height: 4rem;
    ${fontFamilyBold};
    border-radius: .4rem;
    font-size: 1.3rem;
    text-align: center;
    background: #407EDB;
    border: solid 1px #407EDB;

    :hover {
      background: #3B71C4;
    }

    :active {
      background: #3463AB;
    }
  }

  .delete-button {
    background: #fff !important;
    border: 1px solid #C7D6EA !important;
    color: #2A2C2F !important;
    ${fontFamilyMedium};

    :hover {
      background: rgba(64, 125, 217, 0.1) !important;
    }

    :active {
      background: rgba(64, 125, 217, 0.3) !important;
    }
  }
`;

const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 60px;
  width: 100%;
  height: calc(100vh - 60px);
  background-color: rgba(0, 0, 0, .5);

  z-index: 10;
  @media screen and (max-width: 764px) {
    top: 53px !important;
    height: calc(100vh - 53px) !important;
  }
`;

const AdminWrap = styled.div`

  .approval_row {
    background: #fff;
    padding: 2.4rem 3.2rem;

    .approval_tit {
      font-size: 1.2rem;
      line-height: 3.2rem;
      width: 9rem;
      min-width: 8rem;
    }

    .commu_select_wrap {
      display: flex;
    }

    @media screen and (max-width: 413px) {
      .approval_tit {
        width: auto;
      }

      .commu_select_wrap {
        flex-wrap: wrap;

        > button {
          margin-left: 0;
          margin-top: 0.8rem;
        }
      }
    }

    .approval_text {
      color: #2A2C2F;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 3.2rem;
    }

    .approval_icon {
      display: flex;
      justify-content: start;
      align-items: center;
      line-height: 3.2rem;

      > span {
        height: 1.6rem;
        line-height: 1.6rem;
        font-size: 10px;
        font-weight: 500;
        display: flex;
        justify-content: start;
        align-items: center;
        margin-right: 0.4rem;
      }

      > span:before {
        content: '';
        background-size: 100% auto;
        display: inline-block;
        height: 1.6rem;
        line-height: 1.6rem;
        width: 1.6rem;
        margin-right: 0.4rem;
      }

      > span:first-child {
        height: 2.4rem;
        line-height: 2.4rem;
        font-size: 10px;
        font-weight: 500;
        display: flex;
        justify-content: start;
        align-items: center;
        margin-right: 0.4rem;
      }

      > span:first-child:before {
        content: '';
        background-size: 100% auto;
        display: inline-block;
        height: 2.4rem;
        line-height: 2.4rem;
        width: 2.4rem;
        margin-right: 0.4rem;
      }

      .commufeed_icon {
        margin-right: 0.8rem;
      }

      .commufeed_icon:before {
        background: url(${SsqImages.boris.allMappedFeedbacks()});
      }

      .commusmail_icon {
        color: #407DD8;
      }

      .commusmail_icon:before {
        background: url(${SsqImages.community.icon.communitySmile16()});
      }

      .commuangry_icon {
        color: #FF215D;
      }

      .commuangry_icon:before {
        background: url(${SsqImages.community.icon.communityAngry16()});
      }

      .commualarm_icon {
        color: #FF215D;
      }

      .commualarm_icon:before {
        background: url(${SsqImages.community.icon.communityAlarm()});
      }

    }

    select {
      background-position: center right .8rem;
      width: 16rem;
      padding: 0 1.2rem;
      color: #2A2C2F;
    }

    select:disabled {
      background-color: rgba(199, 214, 234, 0.5);
      color: #2A2C2F;
    }

    select:disabled:active {
      background: rgba(199, 214, 234, 0.5) url(${SsqImages.common.icon.select()}) no-repeat center right .8rem;
    }
  }

  .radio_wrap input[type="radio"] {
    display: none;

    + label {
      position: relative;
      display: inline-block;
      width: 7rem;
      height: 3.2rem;
      line-height: 3.2rem;
      background: #fff;
      border: 1px solid #C7D6EA;
      text-align: center;
      margin-left: -1px;
      font-size: 1.2rem;
      color: #2A2C2F;
      cursor: pointer;
    }

    :checked + label {
      background: #407DD9 !important;
      color: #fff !important;
      border: solid 1px #407DD9 !important;
      z-index: 1;
      opacity: 1;
    }
  }

  .error-message {
    color: #E6003E;
    position: absolute;
    left: 4px;
    bottom: 0;
    font-size: 10px;
  }

  .input-error {
    border: 1px solid #E6003E !important;
    position: relative;
  }

  .radio_wrap span:first-of-type label {
    border-radius: 4px 0 0 4px;
  }

  .radio_wrap span:last-child input + label {
    border-radius: 0 4px 4px 0;
  }

  .flex_row {
    clear: both;
    height: 8rem;

    > div {
      float: left;
      display: flex;
      width: 30%;
    }

    > div:first-child:not(.approval_tit) {
      width: 40%;
    }

    + .flex_row {
      margin-top: 1px;
    }
  }

  .approval_state {
    line-height: 3.2rem;
    font-size: 1.2rem;

    img {
      margin-right: .4rem;
      vertical-align: sub
    }

    .name, .time {
      color: #7783A1;
      font-size: 1rem;
    }

    .name {
      margin: .8rem
    }
  }

  .editor_wrap {
    margin: 1.6rem 0;
    width: 84rem;

    .editor_tit {
      background: #fff;
      width: 100%;
      height: 6.4rem;
      padding: 0 3.2rem;

      input {
        ${fontFamilyMedium};
        width: 100%;
        height: 3.2rem;
        margin: 1.6rem 0px;
        font-size: 1.8rem;

        ::placeholder {
          ${fontFamilyRegular};
          font-size: 1.3rem;
          color: #7783A1;
        }
      }
    }

    .editor_txt {
      max-width: 100%;

      .cke_chrome {
        border: none;
        max-width: 100%;

      }
    }

    .cke_top {
      background: #F5F8FD;
      border-bottom: none;
      padding: 6px 6px 2px;

      .cke_button {
        padding: 5px;

        :hover, :active {
          border: none !important;
          background: rgba(64, 126, 219, 0.1) !important;
        }
      }

      .cke_button_on {
        border: none !important;
        background: rgba(64, 126, 219, 0.3) !important;
      }

      .cke_combo_button {
        :hover, :active {
          border: none !important;
          background: rgba(64, 126, 219, 0.1) !important;
        }
      }
    }

    .input-error + .input-error {
      border-top: none !important;
    }
  }

  .add_btn {
    color: #407EDB;
    ${fontFamilyBold};
    font-size: 1.2rem;
    line-height: 16px;
    margin-top: 1rem;

    img {
      margin-right: .8rem;
      vertical-align: middle
    }
  }

  .category_row {
    padding: 2.4rem 3.2rem;

    .approval_tit {
      margin-bottom: 1.6rem;
      line-height: 2.4rem;
      width: auto;

      > span {
        font-size: 1rem;
        color: #7783A1;
        margin-left: 3rem;
        ${fontFamilyRegular};
      }
    }

    .category_select_level_wrap {
      + div {
        margin-top: 1.2rem;
      }

      select + select {
        margin-left: .8rem
      }
    }

    .category_item {
      display: flex;

      + .category_item {
        margin-top: 1.2rem;
      }
    }

    .add_btn img {
      vertical-align: bottom;
    }
  }

  .category_chk_row {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px #EEF1F6;
    padding-bottom: 2.4rem;

    + .category_chk_row {
      padding-top: 2.4rem
    }

    .category_chk {
      width: calc(100% - 11rem);
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -1.2rem;
    }

    input[type="checkbox"] {
      display: none;

      + label {
        cursor: pointer;
        position: relative;
        display: inline-block;
        width: 12rem;
        height: 3.2rem;
        font-size: 1.2rem;
        line-height: 3.2rem;
        background: #FFFFFF;
        border: 1px solid #C7D6EA;
        text-align: center;
        border-radius: 100px;
        margin-right: .8rem;
        margin-bottom: 1.2rem;
      }

      :checked + label {
        background: #407DD9;
        color: #fff;
        border: solid 1px #407DD9;
        z-index: 1;
        ${fontFamilyBold}
      }
    }
  }

  .Version_wrap {
    border: none;

    > div {
      width: 84rem
    }

    .Version_item {

      .Version_tit {
        font-size: 1.6rem;
        ${fontFamilyBold};
        margin-bottom: 2.4rem;
      }

      .Version_desc {
        font-size: 1.2rem;
        ${fontFamilyRegular};
        line-height: 1.5;

        a:link, a:visited {
          font-size: 1.5rem;
          color: #0782C1;
        }
      }

      .Version_desc p {
        font-size: 1.5rem
      }


      .Version_desc h1 {
        font-size: 18px;
        color: #407EDB;
        margin-bottom: 4px;

        img {
          width: 100%;
          height: auto
        }
      }

      .Version_desc h2 {
        font-size: 16px;
        color: #2A2C2F;
        padding: 0;
        margin-bottom: 4px;

        img {
          width: 100%;
          height: auto
        }
      }

      .Version_desc h3 {
        font-size: 15px;
        color: #2A2C2F;
        margin-bottom: 4px;

        img {
          width: 100%;
          height: auto
        }
      }


      .Version_desc hr {
        border: 0px;
        border-top: 1px solid #C7D6EA;
        margin: 10px 0;
        height: 0px;
      }


      .Version_desc .table table {
        border-collapse: collapse;
        border-color: #C7D6EA;
        border-top: solid 2px #407DD9;
        border-bottom: solid 1px #90A0B6;
        border-left: none;
        border-right: none;
        max-width: 100%;
      }

      .Version_desc .table table th, .Version_desc .table table td {
        border: solid 1px #C7D6EA;
        background-color: transparent;
        padding: 7px 8px;
        text-align: left;
      }

      .Version_desc .table .ck-table-resized {
        table-layout: fixed;
        width: 100%;
      }

      .Version_desc .subtitle {
        color: #7783a1;

        img {
          width: 100%;
          height: auto
        }
      }

      .Version_desc mark.marker-yellow {
        background: linear-gradient(to top, #fffaa0 50%, transparent 50%);
      }

      .Version_desc .table tr:last-child th, .Version_desc .table tr:last-child td {
        border-bottom: none;
      }

      .Version_desc .table table p {
        padding: 0px;
        margin: 0px;
      }

      .Version_desc span > img {
        margin: auto;
      }

      .cke_widget_embed img {
        margin: 0;
      }


      @media screen and (max-width: 764px) {

        .Version_desc .table table {
          width: 100% !important;
          max-width: 100%;
          table-layout: fixed;
          word-break: break-word;
        }
      }

      .Version_desc u {
        text-decoration: underline;
      }


      .Version_desc .table > figcaption {
        caption-side: top;
        word-break: break-word;
        text-align: center;
        padding: .6em;
      }


      .Version_desc .media {
        clear: both;
        margin: 0.9em 0;
        display: block;
        min-width: 15em;
      }


      .Version_desc .image {
        display: table;
        clear: both;
        text-align: center;
        margin: 0.9em auto;
        min-width: 50px;
      }


      .Version_desc .image img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        min-width: 100%;
      }


      .Version_desc .image-inline {
        display: inline-flex;
        max-width: 100%;
        align-items: flex-start;
      }

      .Version_desc .image-inline picture {
        display: flex;
      }


      .Version_desc .image-inline picture,
      .Version_desc .image-inline img {
        flex-grow: 1;
        flex-shrink: 1;
        max-width: 100%;
      }


      .Version_desc .image.image_resized {
        max-width: 100%;
        display: block;
        box-sizing: border-box;
      }


      .Version_desc .image.image_resized img {
        width: 100%;
      }


      .Version_desc .image.image_resized > figcaption {
        display: block;
      }


      .Version_desc .marker-yellow {
        background-color: var(--ck-highlight-marker-yellow);
      }


      .Version_desc .marker-green {
        background-color: var(--ck-highlight-marker-green);
      }


      .Version_desc .marker-pink {
        background-color: var(--ck-highlight-marker-pink);
      }

      .Version_desc .marker-blue {
        background-color: var(--ck-highlight-marker-blue);
      }

      .Version_desc .pen-red {
        color: var(--ck-highlight-pen-red);
        background-color: transparent;
      }


      .Version_desc .pen-green {
        color: var(--ck-highlight-pen-green);
        background-color: transparent;
      }


      .Version_desc ol {
        list-style-type: decimal;
      }


      .Version_desc ol ol {
        list-style-type: lower-latin;
      }


      .Version_desc ol ol ol {
        list-style-type: lower-roman;
      }

      .Version_desc ol ol ol ol {
        list-style-type: upper-latin;
      }


      .Version_desc ol ol ol ol ol {
        list-style-type: upper-roman;
      }


      .Version_desc ul {
        list-style-type: disc;
      }

      /* @ckeditor/ckeditor5-list/theme/list.css */

      .Version_desc ul ul {
        list-style-type: circle;
      }

      .Version_desc ul ul ul {
        list-style-type: square;
      }

      .Version_desc ul ul ul ul {
        list-style-type: square;
      }

      .Version_desc .image-style-block-align-left,
      .Version_desc .image-style-block-align-right {
        max-width: calc(100% - var(--ck-image-style-spacing));
      }


      .Version_desc .image-style-align-left,
      .Version_desc .image-style-align-right {
        clear: none;
      }


      .Version_desc .image-style-side {
        float: right;
        margin-left: var(--ck-image-style-spacing);
        max-width: 50%;
      }


      .Version_desc .image-style-align-left {
        float: left;
        margin-right: var(--ck-image-style-spacing);
      }


      .Version_desc .image-style-align-center {
        margin-left: auto;
        margin-right: auto;
      }


      .Version_desc .image-style-align-right {
        float: right;
        margin-left: var(--ck-image-style-spacing);
      }


      .Version_desc .image-style-block-align-right {
        margin-right: 0;
        margin-left: auto;
      }


      .Version_desc .image-style-block-align-left {
        margin-left: 0;
        margin-right: auto;
      }


      .Version_desc p + .image-style-align-left,
      .Version_desc p + .image-style-align-right,
      .Version_desc p + .image-style-side {
        margin-top: 0;
      }


      .Version_desc .image-inline.image-style-align-left,
      .Version_desc .image-inline.image-style-align-right {
        margin-top: var(--ck-inline-image-style-spacing);
        margin-bottom: var(--ck-inline-image-style-spacing);
      }


      .Version_desc .image-inline.image-style-align-left {
        margin-right: var(--ck-inline-image-style-spacing);
      }

      .Version_desc .image-inline.image-style-align-right {
        margin-left: var(--ck-inline-image-style-spacing);
      }


      .Version_desc code {
        background-color: hsla(0, 0%, 78%, 0.3);
        padding: .15em;
        border-radius: 2px;
      }


      .Version_desc blockquote {
        overflow: hidden;
        padding: 8px 16px;
        margin-left: 0;
        margin-right: 0;
        font-style: normal;
        font-size: 15px;
        border-left: solid 2px #407dd9;
        background-color: #f7f8fb;
      }


      .Version_desc .text-tiny {
        font-size: .7em;
      }


      .Version_desc .text-small {
        font-size: .85em;
      }


      .Version_desc .text-big {
        font-size: 1.4em;
      }


      .Version_desc .text-huge {
        font-size: 1.8em;
      }

      .Version_desc pre {
        padding: 1em;
        color: hsl(0, 0%, 20.8%);
        background: hsla(0, 0%, 78%, 0.3);
        border: 1px solid hsl(0, 0%, 77%);
        border-radius: 2px;
        text-align: left;
        direction: ltr;
        tab-size: 4;
        white-space: pre-wrap;
        font-style: normal;
        min-width: 200px;
      }

      .Version_desc pre code {
        background: unset;
        padding: 0;
        border-radius: 0;
      }

      + .file_info {
        border-top: 1px dashed #90A0B6;
        margin-top: 2.4rem;
        padding-top: 2.4rem;
      }
    }

    .file_info {

      .file_name {
        color: #407EDB;
        font-size: 1.3rem;
        display: inline-block;
        cursor: pointer;
      }

      button {
        vertical-align: middle;
        margin: 0px 0.7rem;
        cursor: pointer !important;
      }

      > div {
        display: inline-block;
      }

      span {
        font-size: 1.2rem;
        color: #7783A1;
      }

      span:after {
        content: '';
        width: 1px;
        height: 1.5rem;
        background: #C7D6EA;
        margin: 0 .5rem;
        display: inline-block;
        vertical-align: middle;
      }

      span:last-child:after {
        display: none;
      }

      span b {
        color: #407EDB;
        font-weight: normal;
        margin-left: .5rem;
      }

      + .file_info {
        margin-top: .4rem
      }
    }

    + .Version_wrap {
      border-top: solid 1px #EEF1F6;
    }
  }

  .file_select {
    display: inline-block;
    margin-left: 2.7rem;

    select {
      background-position: center right .8rem;
      width: 16rem;
      padding: 0 1.2rem;
      color: #2A2C2F;

      + select {
        margin-left: .8rem;
      }
    }

    select:disabled {
      background-color: rgba(199, 214, 234, 0.5);
      color: #2A2C2F;
    }

    select:disabled:active {
      background: rgba(199, 214, 234, 0.5) url(${SsqImages.common.icon.select()}) no-repeat center right .8rem;
    }
  }

  .approval_wrap .category_row {
    padding: 2.4rem 0 0;
  }

  .reject_popup {
    .popup-content {
      font-size: 1.2rem;

      input {
        border: 1px solid #C7D6EA;
        border-radius: 4px;
        width: 100%;
        height: 20rem;
        padding: 0 .8rem;
        margin-top: .4rem;

        ::placeholder {
          color: #7783A1;
          font-size: 1rem;
        }
      }
    }
  }

  @media screen and (max-width: 1199px) and (min-width: 765px) {
    overflow-x: auto;
    > div:first-of-type {
      width: 120rem;
    }

    .Admin_newdocument_wrap, .Admin_approval_wrap {
      padding: 1.6rem 3rem;
    }

    .editor_wrap, .editor_txt .cke_chrome {
      width: 100% !important;
    }

    .Version_edit_wrap .editor_wrap {
      width: 84rem !important;
    }

    .cke_bottom {
      display: none !important;
    }

    .file_select {
      margin-left: 2.4rem;
      margin-top: 0.6rem;

    }

    .yes-no-btn {
      display: flex;
      flex-wrap: nowrap;
    }

    .category_select_level {
      //display: flex;
      //flex-wrap: nowrap;
    }
  }

  @media screen and (max-width: 920px) and (min-width: 765px) {
    .template-wrap {
      flex-wrap: wrap;
    }

    .product-list select + select {
      margin-top: 0.8rem !important;
    }
  }
  @media screen and (max-width: 764px) {
    overflow-x: scroll;
    > div:first-of-type {
      width: 120rem;
    }

    .Admin_newdocument_wrap, .Admin_approval_wrap {
      width: auto !important;
      padding: 2rem 0;

      .approval_row {
        padding: 1.6rem !important;

        .approval_tit {
          width: 8.5rem;
        }

        .file_info div {
          display: block
        }
      }

      .flex_row {
        height: auto;

        > div {
          float: none;
          width: 100% !important;

          + div {
            margin-top: 1.6rem
          }
        }
      }

      .category_chk_row input[type="checkbox"] + label {
        width: 11.3rem
      }

      .editor_wrap .editor_tit {
        padding: 0 1.6rem
      }

      .editor_wrap .cke_toolbox {
        display: flex;
        flex-wrap: wrap;
      }

      .approval_wrap {
        padding: 0 1.6rem !important;

        > div {
          padding: 1.6rem 0px !important;
        }

        .approval_tit {
          width: auto;
          margin-bottom: .8rem;

          > span {
            display: block;
            margin-left: 0;
            line-height: 1.2;
          }
        }

        .category_chk_row {
          flex-direction: column;

          .category_chk {
            width: 100%;
            margin-top: .8rem;
          }
        }
      }

      .approval_wrap.app-new-area {
        padding: 0 !important;
      }

      .approval_wrap.app-new-area > div {
        padding: 0 !important;
      }

      .approval_wrap.category_row {
        padding: 1.6rem !important;

        > div {
          padding: 0px !important;
        }

      }

      .category_row {
        > div {
          padding: 0
        }

        .category_item {
          align-items: baseline;
        }

        select {
          width: 20rem;
          display: block;

          + select {
            margin: .8rem 0 0 0;
          }
        }
      }

      .Version_wrap {
        > div {
          width: 100%
        }
      }

      .version_row {
        .Version_history_wrap > div {
          padding: 1.6rem
        }

        .file-item .file-name {
          width: 70%
        }

        .file-radio {
          margin: .8rem 2.7rem;
          display: block;
        }

        .not-use-version {
          padding: 1.6rem;
        }
      }

      .Version_edit_wrap {
        padding: 1.6rem;

        .editor_wrap {
          width: auto !important;
        }
      }

      .Version_history_wrap {
        padding: 0
      }

    }
  }
`;
