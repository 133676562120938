import img_ssq_logo from "./common/ssq_logo.svg";
import img_logo_ls_header from "./common/logo_ls_header.svg";
import img_lselectric_logo from "./common/lselectric_logo.svg";
import img_main_banner_ssq_white from "./common/main_banner_ssq_white.svg";
import img_loading from "./common/loading.svg";
import img_error_empty from "./common/error_empty.svg";
import img_error_member from "./common/error_member.png";
import img_error_employee from "./common/error_employee.png";
import img_error_login from "./common/error_login.svg";

import img_no_image from "./common/no_image.svg";
import img_edit_image_btn from "./common/edit_image_btn.svg";
import img_delete_image_btn from "./mypage/delete_image_btn.svg";
import img_download_icon from "./common/download_icon.svg";
import img_download_icon_no from "./common/download_icon_no.svg";
import img_share_icon from "./common/share_icon.svg";
import img_share_icon_black from "./common/share_icon_black.svg";
import img_checkbox_check from "./common/checkbox_check.svg";
import img_open_checkbox from "./common/open_checkbox.svg";
import img_close_checkbox from "./common/close_checkbox.svg";
import img_close_small_icon from "./common/close_small_icon.svg";
import img_arrow_icon from "./common/arrow_icon.svg";
import img_selected_icon from "./common/selected_icon.svg";
import img_select_arrow from "./common/select_arrow.svg";
import img_select_arrow02 from "./common/select_arrow02.svg";
import img_select_arrow_white_down from "./common/select_arrow_white_down.svg";
import img_select_arrow_white_up from "./common/select_arrow_white_up.svg";
import img_checked_card from "./common/checked_card.svg";
import img_adm_approved from "./common/adm_approved.svg";
import img_adm_not from "./common/adm_not.svg";
import img_adm_reject from "./common/adm_reject.svg";
import img_related_suggest_icon from "./common/related_suggest_icon.svg";
import img_recent_search_icon from "./common/recent_search_icon.svg";
import img_view_more_icon from "./common/view_more_icon.svg";
import img_look_another_url from "./common/look_another_url.svg";
import img_empty_search_result from "./common/empty_search_result.svg";
import img_empty_search_result_back from "./common/error_empty_back.svg";
import img_empty_search_result_back_mobile from "./common/error_empty_back_mobile.svg";
import img_homepage_arrow from "./common/homepage_arrow.svg";
import img_uploading_arrow from "./common/uploading_arrow.gif";
import img_user_icon from "./header/user_icon.svg";
import img_user_icon_header from "./header/header_base_profile.svg";
import img_user_icon_blue from "./header/user_icon_blue.svg";
import img_back_button from "./header/back_button.svg";
import img_search_icon from "./header/search_icon.svg";
import img_mobile_search from "./header/mobile_search.svg";
import img_mobile_search_x from "./header/mobile_search_x.svg";
import img_popup_login from "./header/popup_login.svg";
import img_popup_logout from "./header/popup_logout.svg";
import img_popup_mypage from "./header/popup_mypage.svg";
import img_popup_admin from "./header/popup_admin.svg";
import img_mobile_menu from "./header/mobile_menu.svg";
import img_mobile_menu_icon01 from "./header/mobile_menu_icon01.svg";
import img_mobile_menu_icon02 from "./header/mobile_menu_icon02.svg";
import img_mobile_menu_icon03 from "./header/mobile_menu_icon03.svg";
import img_mobile_menu_icon04 from "./header/mobile_menu_icon04.svg";
import img_titleBar_bg from "./header/titleBar_bg.svg";
import img_titleBar_select from "./header/titleBar_select.svg";
import img_login_kakao from "./header/login_kakao.svg";
import img_login_google from "./header/login_google.svg";
import img_login_naver from "./header/login_naver.svg";
import img_popup_loginfail_icon from "./header/popup_loginfail_icon.svg";
import img_popup_email_icon from "./header/popup_email_icon.svg";
import img_popup_password_reset_icon from "./header/popup_password_reset_icon.svg";
import img_popup_password_reset_email_icon from "./header/popup_password_reset_email_icon.svg";
import img_qna from "./header/qna.svg";
import img_blog from "./header/blog.svg";
import img_board from "./header/board.svg";
import img_sample from "./header/sample.svg";
import img_ls_partner from "./header/ls_partner.svg";
import img_rnd from "./header/rnd.svg";
import img_simul from "./header/simul.svg";
import img_asset from "./header/asset.svg";
import img_classroom from "./header/classroom.svg";
import img_remote from "./header/remote.svg";
import img_post_icon from "./header/popup_post.svg";
import img_global_select_icon from "./header/global_select_icon.svg";
import img_global_select_icon_blue_32 from "./header/global_select_icon_blue_32.svg";
import img_new_icon from "images/common/new_icon.svg";
import img_delete_icon from "images/common/delete_icon.svg";
import img_cancel_icon from "images/common/icon_cancel.svg";
import img_save_icon from "images/common/save_icon.svg";
import img_edit_icon from "images/common/edit_icon.svg";
import img_alarm_icon from "images/common/icon_alarm.svg";

import img_link_icon from "./product/link_icon.svg";
import img_homepage_btn from "./product/homepage_btn.svg";
import img_feedback from "./product/feedback.svg";
import img_feedback_click from "./product/feedback_click.svg";
import img_mousescroll from "./product/mousescroll.gif";
import img_icon_side_drive from "./product/icon_side_drive.svg";
import img_icon_sw_service from "./product/icon_sw_service.svg";
import img_icon_side_hmi from "./product/icon_side_hmi.svg";
import img_icon_side_deboost from "./product/icon_side_deboost.svg";
import img_icon_side_motor from "./product/icon_side_motor.svg";
import img_icon_side_link_blur from "./product/link_icon_blur.svg";
import img_mainIconDrive from "./dashboard/icon_main_drive.svg";
import img_mainIconHmi from "./dashboard/icon_main_hmi.svg";
import sl_mobile_maintxtbg from "images/dashboard/sl_mobile_maintxtbg.png";
import sl_more from "images/dashboard/sl_more.svg";
import img_search_close_icon from "./dashboard/search_close_icon.svg";
import img_product_arrow from "./dashboard/product_arrow.svg";
import img_btn_icon01 from "./dashboard/btn_icon01.svg";
import img_btn_icon01_on from "./dashboard/btn_icon01_on.svg";
import img_btn_icon02 from "./dashboard/btn_icon02.svg";
import img_btn_icon02_on from "./dashboard/btn_icon02_on.svg";
import img_btn_icon03 from "./dashboard/btn_icon03.svg";
import img_btn_icon03_on from "./dashboard/btn_icon03_on.svg";
import img_btn_icon04 from "./dashboard/btn_icon04.svg";
import img_btn_icon04_on from "./dashboard/btn_icon04_on.svg";
import img_btn_icon06 from "./dashboard/btn_icon06.svg";
import img_btn_icon06_on from "./dashboard/btn_icon06_on.svg";
import img_btn_icon07 from "./dashboard/btn_icon07.svg";
import img_btn_icon07_on from "./dashboard/btn_icon07_on.svg";
import img_btn_icon08 from "./dashboard/btn_icon08.svg";
import img_btn_icon08_on from "./dashboard/btn_icon08_on.svg";
import img_btn_icon09 from "./dashboard/btn_icon09.svg";
import img_btn_icon09_on from "./dashboard/btn_icon09_on.svg";
import img_btn_link_icon from "./dashboard/btn_link_icon.svg";
import img_tech_logo from "./dashboard/tech_logo.svg";
import img_tip_icon from "./dashboard/tip_icon.svg";
import img_combg_qna from "./dashboard/combg_qna.svg";
import img_combg_blog from "./dashboard/combg_blog.svg";
import img_combg_board from "./dashboard/visual_sol_icon.svg";
import img_combg_sample from "./dashboard/visual_sample_icon.svg";
import sl_selector_mobile from "./dashboard/sl_selector_mobile.png";
import sl_selector_inner from "./dashboard/sl_selector_img.png";
import sl_selector_bg from "./dashboard/sl_selector_bg.png";
import sl_community_img from "./dashboard/sl_community_img.png";
import sl_community_mobile from "./dashboard/sl_community_mobile.png";
import img_techsquare_bg from "./dashboard/techsquare_bg.png";
import img_techsupport_bg from "./dashboard/techsupport_bg.png";

import img_search_result_icon from "./common/search_result_icon.svg";
import img_search_ent_btn from "./boris/search_ent_btn.svg";
import img_comments_btn from "./boris/img_comments_btn.svg";
import img_tree_updown from "./boris/tree_updown.svg";
import img_tree_arrow from "./boris/tree_arrow.svg";
import img_model_remove from "./boris/model_remove.svg";
import img_approved_com from "./boris/approved_com.svg";
import img_add_icon from "./boris/add_icon.svg";
import img_remove_icon from "./boris/remove_icon.svg";
import img_version_edit from "./boris/version_edit.svg";
import img_list_empty from "./boris/list_empty.svg";
import img_icon_notice from "./boris/icon_notice.svg";
import img_feedback_mail from "./boris/feedback_mail.svg";
import img_all_mapped_feedbacks from "./boris/all_mapped_feedbacks.svg";
import img_feedback_adm from "./boris/feedback_adm.svg";
import img_feedback_check from "./boris/feedback_check.svg";
import img_icon_tag_manage from "./boris/icon_tag_manage.svg";
import img_icon_tag_close_dark from "./boris/icon_tag_close_dark.svg";
import partner_user_delete from "./boris/partner_user_delete.svg";

import img_footer_ssq from "./footer/footer_ssq.svg";
import img_footer_top from "./footer/footer_top.svg";
import footer_chat_hero from "./footer/footer_chat_hero.png";
import footer_chat_beta from "./footer/footer_chat_beta.svg";
import footer_chat_text from "./footer/footer_chat_text.svg";
import img_info_icon01 from "./footer/info_icon01.svg";
import img_info_icon02 from "./footer/info_icon02.svg";
import img_info_icon03 from "./footer/info_icon03.svg";
import img_info_icon04 from "./footer/info_icon04.svg";
import img_sns_youtube from "./footer/sns_youtube.svg";
import img_sns_facebook from "./footer/sns_facebook.svg";
import img_sns_instagram from "./footer/sns_instagram.svg";
import img_sns_linked from "./footer/sns_linked.svg";
import img_sns_blog from "./footer/sns_blog.svg";
import img_sns_navertv from "./footer/sns_navertv.svg";
import img_idea_event_logo from "./event/idea_event_logo.svg";
import img_avatar_01 from "./avatar/avatar01.png";
import img_avatar_02 from "./avatar/avatar02.png";
import img_avatar_03 from "./avatar/avatar03.png";
import img_avatar_04 from "./avatar/avatar04.png";
import img_avatar_05 from "./avatar/avatar05.png";
import img_avatar_06 from "./avatar/avatar06.png";
import img_avatar_07 from "./avatar/avatar07.png";
import img_avatar_08 from "./avatar/avatar08.png";
import my_photo from "./avatar/myphoto.svg";
import img_edit_avatar from "./mypage/edit_avatar.svg";
import img_send_mail from "./mypage/send_mail.svg";
import img_add_tag_icon from "./mypage/add_icon.svg";
import img_tag_icon_plus from "./mypage/tag_icon_plus.svg";
import img_tag_icon_search from "./mypage/tag_icon_search.svg";
import img_tag_icon_time from "./mypage/tag_icon_time.svg";

import img_technical_blog_book from "./community/technicalBlog_book.svg";
import img_board_speaker from "./community/board_speaker.svg";
import CommunityVisualQnaIcon from "images/community/community_visual_qna.svg";

import img_icon_c_footer_qna from "./community/icon_c_footer_qna.svg";
import img_icon_c_footer_blog from "./community/icon_c_footer_blog.svg";
import img_icon_c_footer_info from "./community/icon_c_footer_info.svg";
import img_icon_c_footer_write from "./community/icon_c_footer_write.svg";
import img_icon_c_footer_write_on from "./community/icon_c_footer_write_on.svg";
import img_icon_c_footer_Sample from "./community/icon_c_footer_sample.svg";

import img_icon_c_time from "./common/icon_c_time.svg";
import img_icon_c_time_rotate from "./common/icon_time_modify.svg";
import img_icon_write_modify from "./common/icon_my_write.svg";
import img_icon_c_view from "./common/icon_c_view.svg";
import img_icon_c_good from "./common/icon_c_good.svg";
import img_icon_c_not_bad from "./common/icon_c_not_bad.svg";
import img_icon_c_good_24 from "./common/comm_view_icon_good.svg";
import img_icon_c_not_bad_24 from "./common/comm_view_icon_bad.svg";
import img_view_icon_good_on from "./community/view_icon_good_on.svg";
import img_view_icon_bad_on from "./community/view_icon_bad_on.svg";
import img_icon_c_qna_chose from "./common/icon_qna_chose.svg";
import img_icon_c_qna_q from "./common/icon_qna_q.svg";
import img_icon_c_qna_small_a from "./common/icon_qna_t_a.svg";
import img_icon_c_qna_big_a from "./common/view_icon_a.svg";
import img_view_icon_bookmark from "./community/view_icon_bookmark.svg";
import img_view_icon_bookmark_on from "./community/view_icon_bookmark_on.svg";
import img_icon_spot_more from "./community/icon_spot_more.svg";
import img_view_icon_smile from "./community/view_icon_smile.svg";
import img_view_icon_smile_16 from "./common/icon_smile_16.svg";
import img_view_icon_smile_on from "./community/view_icon_smile_on.svg";
import img_view_icon_angry from "./community/view_icon_angry.svg";
import img_view_icon_angry_16 from "./common/icon_angry_16.svg";
import img_view_icon_angry_on from "./community/view_icon_angry_on.svg";
import img_board_check from "./community/board_check.svg";
import img_review_del from "./common/icon_review_del.svg";
import img_review_enter from "./common/icon_review_enter.svg";
import img_icon_btn_send from "./common/post_btn_send.svg";
import icon_feed_alarm from "./common/icon_feed_alarm.svg";
import img_write_icon from "./community/icon_write.svg";
import img_write_icon_w from "./community/icon_write_w.svg";
import icon_resize from "./community/icon_resize.svg";
import icon_feedback_send from "./community/icon_feedback_send.svg";
import icon_rating from "./community/icon_rating.svg";
import icon_rating_fill from "./community/icon_rating_fill.svg";
import icon_rating_half from "./community/icon_rating_half.svg";

import img_icon_locked from "./common/icon_locked.svg";
import img_pagelink_icon_gray from "./common/page_link_icon_gray.svg";
import img_pagelink_icon_gray_16 from "./common/page_link_icon_gray_16.svg";
import img_pagelink_icon_white from "./common/page_link_icon_white.svg";
import img_icon_apple from "./service/dn_icon_apple.svg";
import img_icon_etc from "./service/dn_icon_etc.svg";
import img_icon_google from "./service/dn_icon_google.svg";
import img_icon_cart from "./service/icon_cart.svg";
import img_icon_cart_plus from "./service/icon_cart_plus.svg";
import img_icon_cart_added from "./service/icon_cart_added.svg";
import img_icon_btn_doc from "./service/icon_doc.svg";
import img_icon_efficiency from "./service/highefficiency.svg";
import img_icon_btn_option from "./service/icon_option.svg";
import img_icon_keyword_search from "images/community/icon_keyword_search.svg";
import icon_user_manual from "images/service/icon_user_manual.svg";
import icon_catalog from "images/service/icon_catalog.svg";
import icon_datasheet from "images/service/icon_datasheet.svg";
import icon_cad_data from "images/service/icon_cad_data.svg";
import icon_arrow_circle from "images/service/icon_arrow_circle.svg";
import energysave from "images/dashboard/sl_energysave.png";
import icon_cate_fan from "images/service/icon_cate_fan.svg";
import icon_cate_pump from "images/service/icon_cate_pump.svg";
import icon_plus from "images/service/icon_plus.svg";
import icon_minus from "images/service/icon_minus.svg";
import icon_type_copy from "images/service/icon_type_copy.svg";
import icon_cost from "images/service/icon_cost.svg";
import icon_energy from "images/service/icon_energy.svg";
import icon_co2 from "images/service/icon_co2.svg";
import icon_tooltip from "images/service/icon_tooltip.svg";
import icon_tooltip_arrow from "images/service/icon_tooltip_arrow.svg";
import icon_link_arrow from "images/service/icon_link_arrow.svg";
import icon_info from "images/service/icon_info.svg";
import empty_chart from "images/service/empty_chart.svg";
import icon_setting from "images/service/icon_setting.svg";
import report_tit from "images/service/report_tit.svg";
import report_print from "images/service/report_btn_print.svg";
import report_down from "images/service/report_btn_down.svg";
import recommend_model from "images/service/icon_recom_model.svg";
import beta_text from "images/service/asset/beta_text.svg";
import link_btn from "images/service/asset/link_btn.svg";
import icon_help from "images/service/asset/icon_help.svg";
import section01_img from "images/service/asset/section01_img.png";
import section02_img from "images/service/asset/section02_img.png";
import section03_bg from "images/service/asset/section03_bg.png";
import section03_img from "images/service/asset/section03_img.png";
import section03_mask from "images/service/asset/section03_mask.png";
import section03_screen_img from "images/service/asset/section03_screen_img.png";
import section04_img from "images/service/asset/section04_img.png";

import img_date_picker from "images/common/icon_date_picker.svg";
import img_date_arrow from "images/common/icon_date_arrow.svg";
import icon_box_arrowd from "images/common/icon_box_arrowd.svg";
import icon_box_arrowd_on from "images/common/icon_box_arrowd_on.svg";
import icon_down_pdf from "images/common/icon_down_pdf.svg";
import icon_down_xls from "images/common/icon_down_xls.svg";
import icon_arrow_line_white from "images/common/icon_line_arrow_white.svg";
import icon_file_open from "images/common/icon_open.svg";
import icon_file_save from "images/common/icon_save.svg";
import img_icon_fullscrren from "images/common/full_screen_icon_black.svg";
import img_community_rnd from "./community/rnd_visual.png";
import img_community_partner_logo from "./community/partner_visual_logo.svg";
import img_community_rnd_mo from "./community/rnd_visual_mo.png";
import img_community_partner_mo from "./community/partner_visual_mo.png";
import img_community_partner from "./community/partner_visual.png";
import img_community_partner_qna_link from "./community/partner_qna_link.png";
import {cdn_imag_url_base} from "conf/gconf";
import img_user_settings from "images/header/user_setting_icon.svg";
import tag_search from "images/common/tag_search.svg";
import tag_input from "images/common/tag_input.svg";
import tag_plus from "images/common/tag_plus.svg";
import tag_plus_on from "images/common/tag_plus_on.svg";
import tag_time from "images/common/tag_time.svg";
import input_search from "images/common/input_search.svg";

const img_technical_blog_person = cdn_imag_url_base + "/community/technicalBlog_person.png";

const CommunityVisualQnaPerson = cdn_imag_url_base + "/community/community_visual_main.png";
const img_event_Popup_Image = cdn_imag_url_base + "/event/event_Popup_Image.png";

const img_download_img = cdn_imag_url_base + "./dashboard/download_img.png";

const img_community_qna = cdn_imag_url_base + "./dashboard/community_qna.png";
const img_community_blog = cdn_imag_url_base + "./dashboard/community_blog.png";
const img_community_board = cdn_imag_url_base + "./dashboard/community_board.png";
const img_community_sample = cdn_imag_url_base + "./dashboard/visual_sample_main.png";

const img_board_person = cdn_imag_url_base + "/community/board_person.png";

const img_popup_event = cdn_imag_url_base + "./dashboard/popup_event.png";
const img_popup_event_en = cdn_imag_url_base + "./dashboard/popup_event_en.png";

export const SsqImages = {
  common: {
    logo: {
      ssq: () => {
        return img_ssq_logo;
      },
      lsElectric: () => {
        return img_lselectric_logo;
      },

      lsHeader: () => {
        return img_logo_ls_header;
      },
      ssqWhite: () => {
        return img_main_banner_ssq_white;
      }
    },
    loading: () => {
      return img_loading;
    },
    emptyError: () => {
      return img_error_empty;
    },
    memberError: () => {
      return img_error_member;
    },
    employeeError: () => {
      return img_error_employee;
    },
    loginError: () => {
      return img_error_login;
    },

    icon: {
      noImage: () => {
        return img_no_image;
      },
      editImageBtn: () => {
        return img_edit_image_btn;
      },
      deleteImageBtn: () => {
        return img_delete_image_btn;
      },
      downloadImg: () => {
        return img_download_icon;
      },
      nondownloadImg: () => {
        return img_download_icon_no;
      },
      shareImg: () => {
        return img_share_icon;
      },
      shareImgBlack: () => {
        return img_share_icon_black;
      },
      fullScreenIcon: () => {
        return img_icon_fullscrren;
      },
      searchResultImg: () => {
        return img_search_result_icon;
      },
      CheckboxCheck: () => {
        return img_checkbox_check;
      },
      openCheckbox: () => {
        return img_open_checkbox;
      },
      closeCheckbox: () => {
        return img_close_checkbox;
      },
      closeSmallIcon: () => {
        return img_close_small_icon;
      },
      arrowIcon: () => {
        return img_arrow_icon;
      },
      selectedIcon: () => {
        return img_selected_icon;
      },
      select: () => {
        return img_select_arrow;
      },
      select02: () => {
        return img_select_arrow02;
      },
      selectWhiteDown: () => {
        return img_select_arrow_white_down;
      },
      selectWhiteUp: () => {
        return img_select_arrow_white_up;
      },
      checkedCard: () => {
        return img_checked_card;
      },
      admissionapproved: () => {
        return img_adm_approved;
      },
      admissionnot: () => {
        return img_adm_not;
      },
      admissionreject: () => {
        return img_adm_reject;
      },
      relatedSuggestResultIcon: () => {
        return img_related_suggest_icon;
      },
      recentSearchIcon: () => {
        return img_recent_search_icon;
      },
      viewMoreIcon: () => {
        return img_view_more_icon;
      },
      viewDetail: () => {
        return img_look_another_url;
      },
      emptySearchResult: () => {
        return img_empty_search_result;
      },
      emptySearchResultBack: () => {
        return img_empty_search_result_back;
      },
      emptySearchResultBackMobile: () => {
        return img_empty_search_result_back_mobile;
      },
      homepageArrow: () => {
        return img_homepage_arrow;
      },
      uploadingArrow: () => {
        return img_uploading_arrow;
      },
      pagelinkGray: () => {
        return img_pagelink_icon_gray;
      },
      pageLinkGray16: () => {
        return img_pagelink_icon_gray_16;
      },
      pagelinkWhite: () => {
        return img_pagelink_icon_white;
      },
      downPdf: () => {
        return icon_down_pdf;
      },
      downXls: () => {
        return icon_down_xls;
      },

      fileOpen: () => {
        return icon_file_open;
      },

      fileSave: () => {
        return icon_file_save;
      },

      arrowLineWhite: () => {
        return icon_arrow_line_white;
      },
      newMark: () => {
        return img_new_icon;
      },
      deleteIcon: () => {
        return img_delete_icon;
      },
      cancelIcon: () => {
        return img_cancel_icon;
      },
      saveIcon: () => {
        return img_save_icon;
      },
      editIcon: () => {
        return img_edit_icon;
      },
      alarmIcon: () => {
        return img_alarm_icon;
      },
      dateInput: () => {
        return img_date_picker;
      },
      dateArrow: () => {
        return img_date_arrow;
      },
      boxArrowDown: () => {
        return icon_box_arrowd;
      },
      boxArrowDownOn: () => {
        return icon_box_arrowd_on;
      },
      tagSearch: () => {
        return tag_search;
      },
      tagInput: () => {
        return tag_input;
      },
      tagTime: () => {
        return tag_time;
      },
      tagPlus: () => {
        return tag_plus;
      },
      tagPlusOn: () => {
        return tag_plus_on;
      },
      inputSearch: () => {
        return input_search;
      },
    }
  },
  header: {
    icon: {
      user: () => {
        return img_user_icon;
      },
      userHeader: () => {
        return img_user_icon_header;
      },
      loggedInUser: () => {
        return img_user_icon_blue;
      },
      userSettings: () => {
        return img_user_settings;
      },
      backButton: () => {
        return img_back_button;
      },
      search: () => {
        return img_search_icon;
      },
      mobilesearch: () => {
        return img_mobile_search;
      },
      mobilesearchclose: () => {
        return img_mobile_search_x;
      },
      login: () => {
        return img_popup_login;
      },
      logout: () => {
        return img_popup_logout;
      },
      mypage: () => {
        return img_popup_mypage;
      },
      boris: () => {
        return img_popup_admin;
      },
      mobilemenu: () => {
        return img_mobile_menu;
      },
      mobilemenusub01: () => {
        return img_mobile_menu_icon01;
      },
      mobilemenusub02: () => {
        return img_mobile_menu_icon02;
      },
      mobilemenusub03: () => {
        return img_mobile_menu_icon03;
      },
      mobilemenusub04: () => {
        return img_mobile_menu_icon04;
      },
      category: (imageUrl: string) => {
        if (imageUrl == "qna.svg") return img_qna;
        else if (imageUrl == "blog.svg") return img_blog;
        else if (imageUrl == "board.svg") return img_board;
        else if (imageUrl == "sample.svg") return img_sample;
        else if (imageUrl == "classroom.svg") return img_classroom;
        else if (imageUrl == "remote.svg") return img_remote;
        else if (imageUrl == "icon_side_drive.svg") return img_icon_side_drive;
        else if (imageUrl == "icon_side_deboost.svg") return img_icon_side_deboost;
        else if (imageUrl == "icon_side_motor.svg") return img_icon_side_motor;
        else if (imageUrl === "icon_sw_service.svg") return img_icon_sw_service;
        else if (imageUrl === "partner.svg") return img_ls_partner;
        else if (imageUrl == "rnd.svg") return img_rnd;
        else if (imageUrl == "simul.svg") return img_simul;
        else if (imageUrl == "asset.svg") return img_asset;
      },
      post: () => {
        return img_post_icon;
      },
      global_select_icon: () => {
        return img_global_select_icon;
      },
      globalSelectIconBlue32: () => {
        return img_global_select_icon_blue_32;
      }
    },
    titlebarbg: () => {
      return img_titleBar_bg;
    },
    titlebarselect: () => {
      return img_titleBar_select;
    },
    sns: {
      kakao: () => {
        return img_login_kakao;
      },
      google: () => {
        return img_login_google;
      },
      naver: () => {
        return img_login_naver;
      }
    },
    popup: {
      loginfail: () => {
        return img_popup_loginfail_icon;
      },
      sendemail: () => {
        return img_popup_email_icon;
      },
      passwordReset: () => {
        return img_popup_password_reset_icon;
      },
      passwordResetEmail: () => {
        return img_popup_password_reset_email_icon;
      }
    }
  },
  product: {
    linkicon: () => {
      return img_link_icon;
    },
    homepage: () => {
      return img_homepage_btn;
    },
    feedback: () => {
      return img_feedback;
    },
    feedbackclick: () => {
      return img_feedback_click;
    },
    mousescroll: () => {
      return img_mousescroll;
    },
    SideGuideDrive: () => {
      return img_icon_side_drive;
    },
    SideGuideHmi: () => {
      return img_icon_side_hmi;
    },
    SideGuideDeboost: () => {
      return img_icon_side_deboost;
    },
    SideGuideMotor: () => {
      return img_icon_side_motor;
    },
    SideGuideLink: () => {
      return img_icon_side_link_blur;
    }
  },
  dashboard: {
    slideSelectorBg: () => {
      return sl_selector_bg;
    },
    slideSelectorImg: () => {
      return sl_selector_inner;
    },
    slideSelectorMobile: () => {
      return sl_selector_mobile;
    },
    slideCommuImg: () => {
      return sl_community_img;
    },
    slideCommuMobile: () => {
      return sl_community_mobile;
    },
    slideMobileTxtbg: () => {
      return sl_mobile_maintxtbg;
    },
    slideArrow: () => {
      return sl_more;
    },
    techSquareBg: () => {
      return img_techsquare_bg;
    },
    techSupportBg: () => {
      return img_techsupport_bg;
    },
    mainIconDrive: () => {
      return img_mainIconDrive;
    },
    mainIconHmi: () => {
      return img_mainIconHmi;
    },
    searchCloseImage: () => {
      return img_search_close_icon;
    },
    productarrow: () => {
      return img_product_arrow;
    },
    DownloadImage: () => {
      return img_download_img;
    },
    commQna: () => {
      return img_community_qna;
    },
    commBlog: () => {
      return img_community_blog;
    },
    commBoard: () => {
      return img_community_board;
    },
    commSample: () => {
      return img_community_sample;
    },
    combgQna: () => {
      return img_combg_qna;
    },
    combgBlog: () => {
      return img_combg_blog;
    },
    combgBoard: () => {
      return img_combg_board;
    },
    combgSample: () => {
      return img_combg_sample;
    },
    icon: {
      icon01: () => {
        return img_btn_icon01;
      },
      icon01_on: () => {
        return img_btn_icon01_on;
      },
      icon02: () => {
        return img_btn_icon02;
      },
      icon02_on: () => {
        return img_btn_icon02_on;
      },
      icon03: () => {
        return img_btn_icon03;
      },
      icon03_on: () => {
        return img_btn_icon03_on;
      },
      icon04: () => {
        return img_btn_icon04;
      },
      icon04_on: () => {
        return img_btn_icon04_on;
      },
      icon06: () => {
        return img_btn_icon06;
      },
      icon06_on: () => {
        return img_btn_icon06_on;
      },
      icon07: () => {
        return img_btn_icon07;
      },
      icon07_on: () => {
        return img_btn_icon07_on;
      },
      icon08: () => {
        return img_btn_icon08;
      },
      icon08_on: () => {
        return img_btn_icon08_on;
      },
      icon09: () => {
        return img_btn_icon09;
      },
      icon09_on: () => {
        return img_btn_icon09_on;
      },
    },
    btnlink: () => {
      return img_btn_link_icon;
    },
    techlogo: () => {
      return img_tech_logo;
    },
    tip: () => {
      return img_tip_icon;
    },
    eventpopup: () => {
      return img_popup_event;
    },
    eventpopupen: () => {
      return img_popup_event_en;
    },
  },
  boris: {
    tablink: () => {
      return img_search_result_icon;
    },
    searchEnterButton: () => {
      return img_search_ent_btn;
    },
    CommentsBtn: () => {
      return img_comments_btn;
    },
    treeupdown: () => {
      return img_tree_updown;
    },
    treearrow: () => {
      return img_tree_arrow;
    },
    modelremove: () => {
      return img_model_remove;
    },
    approvedcm: () => {
      return img_approved_com;
    },
    addrow: () => {
      return img_add_icon;
    },
    removerow: () => {
      return img_remove_icon;
    },
    edit: () => {
      return img_version_edit;
    },
    listempty: () => {
      return img_list_empty;
    },
    listNotice: () => {
      return img_icon_notice;
    },
    feedbackMail: () => {
      return img_feedback_mail;
    },
    allMappedFeedbacks: () => {
      return img_all_mapped_feedbacks;
    },
    feedback: () => {
      return img_feedback_adm;
    },
    feedbackCheck: () => {
      return img_feedback_check;
    },
    writeTagCloseDark: () => {
      return img_icon_tag_close_dark;
    },
    writeTagManage: () => {
      return img_icon_tag_manage;
    },
    searchSmallIcon: () => {
      return img_icon_keyword_search;
    },
    partnerUserDelete: () => {
      return partner_user_delete;
    }
  },
  footer: {
    ftssq: () => {
      return img_footer_ssq;
    },
    top: () => {
      return img_footer_top;
    },
    chatBotHero: () => {
      return footer_chat_hero;
    },
    chatBotBeta: () => {
      return footer_chat_beta;
    },
    chatBotTextBg: () => {
      return footer_chat_text;
    },
    infoicon01: () => {
      return img_info_icon01;
    },
    infoicon02: () => {
      return img_info_icon02;
    },
    infoicon03: () => {
      return img_info_icon03;
    },
    infoicon04: () => {
      return img_info_icon04;
    },
    sns: {
      youtube: () => {
        return img_sns_youtube;
      },
      facebook: () => {
        return img_sns_facebook;
      },
      instagram: () => {
        return img_sns_instagram;
      },
      linked: () => {
        return img_sns_linked;
      },
      blog: () => {
        return img_sns_blog;
      },
      navertv: () => {
        return img_sns_navertv;
      }
    }
  },
  mypage: {
    editavatar: () => {
      return img_edit_avatar;
    },
    sendmail: () => {
      return img_send_mail;
    },
    addicon: () => {
      return img_add_tag_icon;
    },
    plusicon: () => {
      return img_tag_icon_plus;
    },
    searchicon: () => {
      return img_tag_icon_search;
    },
    timeicon: () => {
      return img_tag_icon_time;
    },
    avatar: {
      avatar01: () => {
        return img_avatar_01;
      },
      avatar02: () => {
        return img_avatar_02;
      },
      avatar03: () => {
        return img_avatar_03;
      },
      avatar04: () => {
        return img_avatar_04;
      },
      avatar05: () => {
        return img_avatar_05;
      },
      avatar06: () => {
        return img_avatar_06;
      },
      avatar07: () => {
        return img_avatar_07;
      },
      avatar08: () => {
        return img_avatar_08;
      },
      myphoto: () => {
        return my_photo;
      }
    }
  },
  event: {
    eventPopupImg: () => {
      return img_event_Popup_Image;
    },
    eventPopupLogoImg: () => {
      return img_idea_event_logo;
    }
  },
  community: {
    visualQnaIcon: () => {
      return CommunityVisualQnaIcon;
    },
    visualQnaPerson: () => {
      return CommunityVisualQnaPerson;
    },
    visualBlogBook: () => {
      return img_technical_blog_book;
    },
    visualBlogPerson: () => {
      return img_technical_blog_person;
    },
    visualBoardSpeaker: () => {
      return img_board_speaker;
    },
    visualBoardPerson: () => {
      return img_board_person;
    },
    visualRnd: () => {
      return img_community_rnd;
    },
    visualRndMobile: () => {
      return img_community_rnd_mo;
    },
    communityFooterQna: () => {
      return img_icon_c_footer_qna;
    },
    communityFooterBlog: () => {
      return img_icon_c_footer_blog;
    },
    communityFooterInfo: () => {
      return img_icon_c_footer_info;
    },
    communityFooterWrite: () => {
      return img_icon_c_footer_write;
    },
    communityFooterWriteOn: () => {
      return img_icon_c_footer_write_on;
    },
    communitySample: () => {
      return img_icon_c_footer_Sample;
    },
    visualPartner: () => {
      return img_community_partner;
    },
    visualPartnerMobile: () => {
      return img_community_partner_mo;
    },
    visualPartnerLogo: () => {
      return img_community_partner_logo;
    },
    communityPartnerQnaLink: () => {
      return img_community_partner_qna_link;
    },

    icon: {
      communityTime: () => {
        return img_icon_c_time;
      },
      communityTimeRotate: () => {
        return img_icon_c_time_rotate;
      },
      communityWriteModify: () => {
        return img_icon_write_modify;
      },
      communityView: () => {
        return img_icon_c_view;
      },
      communityGood: () => {
        return img_icon_c_good;
      },
      communityNotBad: () => {
        return img_icon_c_not_bad;
      },
      communityGood24: () => {
        return img_icon_c_good_24;
      },
      communityNotBad24: () => {
        return img_icon_c_not_bad_24;
      },
      communityGoodOn24: () => {
        return img_view_icon_good_on;
      },
      communityNotBadOn24: () => {
        return img_view_icon_bad_on;
      },
      communityBookmark: () => {
        return img_view_icon_bookmark;
      },
      communityBookmarkOn: () => {
        return img_view_icon_bookmark_on;
      },
      communitySmile: () => {
        return img_view_icon_smile;
      },
      communitySmile16: () => {
        return img_view_icon_smile_16;
      },
      communitySmileOn: () => {
        return img_view_icon_smile_on;
      },
      communityAngry: () => {
        return img_view_icon_angry;
      },
      communityAngry16: () => {
        return img_view_icon_angry_16;
      },
      communityAngryOn: () => {
        return img_view_icon_angry_on;
      },
      communitySpotMore: () => {
        return img_icon_spot_more;
      },
      communityBoardCheck: () => {
        return img_board_check;
      },
      communityReviewDel: () => {
        return img_review_del;
      },
      communityReviewEnter: () => {
        return img_review_enter;
      },
      communityQnaCheck: () => {
        return img_icon_c_qna_chose;
      },
      communityQnaQ: () => {
        return img_icon_c_qna_q;
      },
      communityQnaSmallA: () => {
        return img_icon_c_qna_small_a;
      },
      communityQnaBigA: () => {
        return img_icon_c_qna_big_a;
      },
      communityBtnSend: () => {
        return img_icon_btn_send;
      },
      communityAlarm: () => {
        return icon_feed_alarm;
      },
      writeIcon: () => {
        return img_write_icon;
      },
      writeIconWhite: () => {
        return img_write_icon_w;
      },
      resizeIcon: () => {
        return icon_resize;
      },
      feedbackSend: () => {
        return icon_feedback_send;
      },
      rating: () => {
        return icon_rating;
      },
      ratingFill: () => {
        return icon_rating_fill;
      },
      ratingHalf: () => {
        return icon_rating_half;
      },
    }
  },
  service: {
    icon: {
      serviceLocked: () => {
        return img_icon_locked;
      },
      service_google: () => {
        return img_icon_google;
      },
      service_apple: () => {
        return img_icon_apple;
      },
      service_etc: () => {
        return img_icon_etc;
      },
      cart: () => {
        return img_icon_cart;
      },
      cartPlus: () => {
        return img_icon_cart_plus;
      },
      cartAdded: () => {
        return img_icon_cart_added;
      },
      doc: () => {
        return img_icon_btn_doc;
      },
      highEfficiency: () => {
        return img_icon_efficiency;
      },
      option: () => {
        return img_icon_btn_option;
      },
      userManual: () => {
        return icon_user_manual;
      },
      optionCatalog: () => {
        return icon_catalog;
      },
      dataSheet: () => {
        return icon_datasheet;
      },
      cadData: () => {
        return icon_cad_data;
      },
      arrowCircle: () => {
        return icon_arrow_circle;
      },
      fan: () => {
        return icon_cate_fan;
      },
      pump: () => {
        return icon_cate_pump;
      },
      plusIcon: () => {
        return icon_plus;
      },
      minusIcon: () => {
        return icon_minus;
      },
      typeCopy: () => {
        return icon_type_copy;
      },
      toolTip: () => {
        return icon_tooltip;
      },
      toolTipArrow: () => {
        return icon_tooltip_arrow;
      },
      linkArrow: () => {
        return icon_link_arrow;
      },
      info: () => {
        return icon_info;
      },
      setting: () => {
        return icon_setting;
      }
    },
    energysave: () => {
      return energysave;
    },
    cost: () => {
      return icon_cost;
    },
    energy: () => {
      return icon_energy;
    },
    co2: () => {
      return icon_co2;
    },
    emptyChart: () => {
      return empty_chart;
    },
    reportTit: () => {
      return report_tit;
    },
    reportPrint: () => {
      return report_print;
    },
    reportDown: () => {
      return report_down;
    },
    recommendModel: () => {
      return recommend_model;
    },
    asset: {
      betaText: () => {
        return beta_text;
      },
      linkBtn: () => {
        return link_btn;
      },
      iconHelp: () => {
        return icon_help;
      },
      section01Img: () => {
        return section01_img;
      },
      section02Img: () => {
        return section02_img;
      },
      section03Bg: () => {
        return section03_bg;
      },
      section03Mask: () => {
        return section03_mask;
      },
      section03Img: () => {
        return section03_img;
      },
      section03Screen: () => {
        return section03_screen_img;
      },
      section04Img: () => {
        return section04_img;
      }
    }
  }

};